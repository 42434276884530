/*------------------------------------------------------------------
[Master Stylesheet]

Template Name : Ruper
Description   : Ruper – Furniture HTML Theme
Author        : CakeTheme
Version       : 1.0
Created       : 20/10/2022
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]

1. General
2. Animate
3. Header
  3.1. Header Topbar
  3.2. Header Menu
  3.3. Header Login
  3.4. Header Cart
  3.5. Header Search
  3.6. Header Wishlist
4. Breadcrumbs
5. Block
  5.1. Block Slider
  5.2. Block Banners
  5.3. Block Products
  5.4. Block Product Categories
  5.5. Block Video
  5.6. Block Feature
  5.7. Block Posts
  5.8. Block Lookbook
  5.9. Block Testimonial
  5.10. Block Intro
  5.11. Block Newsletter
  5.12. Block Image
  5.13. Block Instagram
  5.14. Block Countdown
  5.15. Block Map
  5.16. Block Parallax
  5.17. Block Product Filter
  5.18. Blog Post Search
  5.19. Block Post Categories
  5.20. Block Post Archives
  5.21. Block Post Tags
  5.22. Block Contact Map
  5.23. Block Contact Info
  5.24. Block Contact Form
6. Product Functions
  6.1. Product - Wishlist
  6.2. Product - Compare
  6.3. Product - Quickview
7. Shop
  7.1. Shop - Top Bar
  7.2. Shop - Products
  7.3. Shop - Details
  7.4. Shop - Cart
  7.5. Shop - Checkout
  7.6. Shop - Wishlist
8. Blog
  8.1. Blog - Grid
  8.2. Blog - List
  8.3. Blog - Details
9. Pages
  9.1. Login / Register
  9.2. Forget Password
  9.3. My Account
  9.4. About Us
  9.5. Page FAQ
  9.6. Page 404
10. Footer

-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Color codes]

Background  : #ffffff
Content     : #868686

a (standard): #000000
a (visited) : #000000
a (active)  : #000000
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Typography]

Body   : 15px "Barlow Semi Condensed";
Other  : "EB Garamond";
-------------------------------------------------------------------*/

/*===================================================================
1. General
====================================================================*/
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    font-size: 15px;
}
body {
    margin: 0;
    font-family: "Montserrat", sans-serif !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #868686;
    text-align: left;
    background-color: transparent;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
}
img {
    border: 0;
    vertical-align: top;
    max-width: 100%;
    height: auto;
}
a,
a:hover {
    color: #000;
    text-decoration: none;
}
a:focus {
    text-decoration: unset;
    outline: unset;
}
/* input[type=email], 
  input[type=number], 
  input[type=password], 
  input[type=tel], 
  input[type=text], 
  select, 
  textarea {
    border: 1px solid #e5e5e5;
    padding: 7px 15px;
    background: transparent;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    box-shadow: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
  } */
/* input:focus, 
  textarea:focus {
    outline: none;
  } */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.table,
table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
table {
    border-collapse: collapse;
}
button,
.button {
    border: 0;
}
button:focus {
    outline: none;
}
.btn {
    border-radius: 0;
}
.clearfix:after {
    display: block;
    clear: both;
    content: "";
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.position-v-top {
    position: absolute;
    top: 0;
}
.position-v-center {
    align-content: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}
.position-h-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.position-h-center.animation-round {
    left: 45%;
}
.btn:not(:disabled):not(.disabled),
.clear-all:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-primary {
    color: #fff;
    background-color: #223e9c;
    border-color: #223e9c;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
    color: #fff;
    background-color: #2b929f;
    border-color: #288995;
}
.btn-default,
.btn-primary {
    position: relative;
    z-index: 0;
}
.btn-default:before,
.btn-primary:before {
    position: absolute;
    content: "";
    top: 0;
    background: #000;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: -1;
}
.btn-default:hover:before,
.btn-primary:hover:before {
    height: 100%;
    opacity: 1;
    visibility: visible;
}
.btn-default:hover,
.btn-primary:hover {
    color: #fff !important;
    border-color: #000 !important;
}
.btn-underline {
    position: relative;
    padding-bottom: 3px;
    display: inline-block;
}
.btn-underline:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #000;
}
.btn-underline:hover:before {
    width: 50%;
}
.btn-underline.center:hover:before {
    left: 25%;
    right: 25%;
}
.button-white {
    font-size: 13px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 2px;
    padding: 0px 30px;
    background-color: #ffffff;
    display: inline-block;
}
.button-white:hover,
.button-white:focus {
    color: #ffffff;
    background-color: #000000;
}
.button-black {
    font-size: 13px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 2px;
    padding: 0px 30px;
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
    display: inline-block;
}
.button-black:hover,
.button-black:focus {
    color: #000000;
    background: transparent;
    border: 1px solid #000000;
}
.button-outline {
    font-size: 13px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 2px;
    border: 1px solid #000;
    padding: 0px 30px;
    color: #000;
    background: transparent;
    display: inline-block;
}
.button-outline:hover {
    background: #000000;
    color: #fff;
}
.button-outline.white {
    background: #fff;
}
.button-outline.white:hover {
    background: #000;
}
.custom-radio li input {
    display: none;
}
.custom-radio li label {
    margin: 0;
    margin-left: 0;
    font-weight: 400;
    margin-bottom: 0;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
}
.custom-radio li label:before {
    position: absolute;
    left: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border: 1px solid #868686;
    content: "";
}
.custom-radio li input:checked + label:before {
    background: #000;
    border-color: #000;
}
.select2-container {
    width: 100% !important;
}
.section {
    margin-bottom: 50px;
}
.section.top-border {
    border-top: 1px solid #e5e5e5;
}
.section.p-t-20 {
    padding-top: 20px;
}
.section.p-t-50 {
    padding-top: 50px;
}
.section.p-t-70 {
    padding-top: 70px;
}
.section.p-t-80 {
    padding-top: 80px;
}
.section.p-t-100 {
    padding-top: 100px;
}
.section.p-b-20 {
    padding-bottom: 20px;
}
.section.p-b-50 {
    padding-bottom: 50px;
}
.section.p-b-70 {
    padding-bottom: 70px;
}
.section.p-b-80 {
    padding-bottom: 80px;
}
.section.p-b-100 {
    padding-bottom: 100px;
}
.section.content-outside {
    margin-bottom: 410px;
    padding-top: 100px;
}
.section.content-outside .block-widget-wrap > div {
    margin-bottom: -340px;
}
.section.background-1 {
    background-color: #e0e8f1;
}
.section.background-2 {
    background-color: #dfe5dd;
}
.section.background-3 {
    background-color: #f2eee7;
}
.section.background-4 {
    background-color: #eeecf2;
}
.section.background-5 {
    background-color: #cdc2bb;
}
.section.background-6 {
    background-color: #ece5e0;
}
.section.background-7 {
    background-color: #e1ddd9;
}
.section.background-8 {
    background-color: #f5f5f5;
}
.section.background-9 {
    background-color: #ced3cd;
}
.section.background-10 {
    background-color: #d5d8e4;
}
.section.background-11 {
    background-color: #e7ecef;
}
.section.background-12 {
    background-color: #f1eee8;
}
.section.newsletter-background {
    background-image: url(../src/assets/frontimg/banner/newsletter-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 110px;
    padding-bottom: 110px;
}
.section.contact-background {
    background-image: url(../src/assets/frontimg/banner/contact-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0 80px 0;
}
.section-padding {
    padding: 0 22.5px;
}
.section-small-padding {
    padding: 0 10px;
}
.section-container {
    max-width: 1425px;
    margin-right: auto;
    margin-left: auto;
}
.section-container.large {
    max-width: 1770px;
}
.section-container.small {
    max-width: 960px;
}
.section-row {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.section-column,
.section-column-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.section-column-wrap {
    width: 100%;
}
.section-column.left {
    width: 49.405%;
}
.section-column.right {
    width: 50.595%;
}
.section-mb-l {
    margin-bottom: 170px;
}
.no-space .row {
    margin: 0;
}
.no-space .row > div {
    padding: 0;
}
.small-space .row {
    margin: 0 -7.5px;
}
.small-space .row > div {
    padding: 0 7.5px;
}
.x-small-space .row {
    margin: 0 -5px;
}
.x-small-space .row > div {
    padding: 0 5px;
}
.large-space .row {
    margin: 0 -30px;
}
.large-space .row > div {
    padding: 0 30px;
}
.position-center {
    align-content: center;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}
.column-background-wrap {
    padding: 90px 15px 90px 90px;
    background: #d7e0e3;
}
.animation-round {
    animation: l 15s infinite linear;
}
.p-t-5 {
    padding-top: 5px;
}
.p-t-10 {
    padding-top: 10px;
}
.p-t-20 {
    padding-top: 20px;
}
.p-t-30 {
    padding-top: 30px;
}
.p-t-50 {
    padding-top: 50px;
}
.p-t-70 {
    padding-top: 70px;
}
.p-t-80 {
    padding-top: 80px;
}
.p-t-100 {
    padding-top: 100px;
}
.p-b-50 {
    padding-bottom: 50px;
}
.p-b-70 {
    padding-bottom: 70px;
}
.p-b-80 {
    padding-bottom: 80px;
}
.p-b-100 {
    padding-bottom: 100px;
}
.m-t-0 {
    margin-top: 0;
}
.m-t-5 {
    margin-top: 5px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-b-0 {
    margin-bottom: 0;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.m-b-60 {
    margin-bottom: 60px;
}
.m-b-70 {
    margin-bottom: 70px;
}
.m-b-80 {
    margin-bottom: 80px;
}
.m-b-90 {
    margin-bottom: 90px;
}
.p-0 {
    padding: 0;
}
.p-l-r {
    padding-left: 7.5px;
    padding-right: 7.5px;
}
/* .form-control:focus {
    border: none;
    box-shadow: none;
  } */
.dropdown-toggle:after,
.hide {
    display: none;
}
.price {
    line-height: 24px;
    font-size: 15px;
    color: #a0a0a0;
    letter-spacing: 1.5px;
    font-weight: 400;
}
.block-section {
    position: relative;
    width: 100%;
}
.column-50 {
    width: 50%;
}
.column-25 {
    width: 25%;
}
.block-widget-wrap {
    padding: 0px 7.5px 0px 7.5px;
    width: 100%;
}
.block-widget-wrap-2 {
    padding: 0px 15px 0px 15px;
    width: 100%;
}
.fullwidth .block-widget-wrap {
    padding: 0;
}
.full-height {
    height: 100%;
}
.content-product-list .products-thumb {
    display: inline-block;
    vertical-align: top;
}
.rating .star:after,
.rating .star:before {
    display: inline-block;
    color: #f5bf1c;
    font-family: icomoon;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 5px;
}
.rating.small .star:after,
.rating.small .star:before {
    font-size: 13px;
    letter-spacing: 2px;
}
.rating .star.star-1:before {
    content: "\e904";
}
.rating .star.star-2:before {
    content: "\e904\e904";
}
.rating .star.star-3:before {
    content: "\e904\e904\e904";
}
.rating .star.star-4:before {
    content: "\e904\e904\e904\e904";
}
.rating .star.star-5:before {
    content: "\e904\e904\e904\e904\e904";
}
.rating .star:after {
    color: #e1e1e1;
}
.rating .star.star-0:after {
    content: "\e904\e904\e904\e904\e904";
}
.rating .star.star-1:after {
    content: "\e904\e904\e904\e904";
}
.rating .star.star-2:after {
    content: "\e904\e904\e904";
}
.rating .star.star-3:after {
    content: "\e904\e904";
}
.rating .star.star-4:after {
    content: "\e904";
}
.rating .star {
    display: inline-block;
}
.rating .review-count {
    display: inline-block;
    margin-left: 10px;
}
.hot,
.onsale {
    text-align: center !important;
    color: #fff;
    font-size: 14px;
    padding: 0 7px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    position: relative;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: 10px;
    min-width: 47px;
    z-index: 1;
}
.hot {
    right: 10px;
    background: #fff;
    color: red;
}
.onsale {
    left: 10px;
    background: #fff;
    color: #000;
}
del {
    font-size: 14px;
    color: #ababab;
}
.price del span {
    text-decoration: line-through;
    color: red;
}

.price ins {
    text-decoration: none;
    color: black;
}
body.home .section .block-title {
    text-align: center;
    margin-bottom: 40px;
}
body.home .section .block-title h2 {
    margin-bottom: 0;
}
body.home .section .block-title.title-underline {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 40px;
    line-height: 1;
    border-bottom: 1px solid #e4e4e4;
}
body.home .section .block-title.title-underline h2 {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
body.home .section .block-title.title-underline .title-right {
    font-size: 15px;
    position: relative;
    bottom: -14px;
}
body.home .section .block-title.title-underline .title-right a {
    position: relative;
}
body.home .section .block-title.title-underline .title-right a:before {
    position: absolute;
    content: "";
    background: #000;
    width: 100%;
    height: 1px;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
body.home .section .block-title.title-underline .title-right a:hover:before {
    width: 50%;
}
body.home .section .block-title.title-underline .title-right a:after {
    content: "\f04b";
    font-family: FontAwesome;
    position: relative;
    top: -1px;
    display: inline-block;
    color: #000;
    font-size: 10px;
    margin-left: 10px;
}
body.home.title-2 .section .block-title {
    margin-bottom: 45px;
}
body.home.title-2 .section .block-title h2 {
    font-size: 50px;
    line-height: 1;
    color: #868686;
    font-weight: 300;
}
body.home.title-3 .section .block-title {
    margin-bottom: 45px;
}
body.home.title-3 .section .block-title h2 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1;
}
body.home.title-4 .section .block-title h2 {
    font-size: 32px;
    font-weight: 300;
}
body.home.title-6 .section .block-title h2 {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
body.home.title-8 .section .block-title {
    margin-bottom: 40px;
}
body.home.title-8 .section .block-title h2 {
    font-size: 30px;
    font-weight: 300;
}
body.home.title-10 .section .block-title h2 {
    font-size: 22px;
    letter-spacing: 3px;
    text-transform: uppercase;
}
body.home.title-11 .section .block-title h2 {
    font-size: 38px;
    font-weight: 300;
}
body.home.title-12 .section .block-title.title-big h2 {
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 60px;
}
body.home.title-13 .section .block-title {
    margin-bottom: 45px;
}
body.home.title-13 .section .block-title h2 {
    font-size: 28px;
    font-weight: 300;
}
body.home.title-14 .section .block-title h2 {
    font-size: 28px;
    font-weight: 300;
}
body.home.title-15 .section .block-title h2 {
    font-size: 24px;
    letter-spacing: 3px;
    text-transform: uppercase;
}
body.home .section .block-title .sub-title {
    margin-top: 10px;
}
body i.slick-arrow {
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -10px;
    position: absolute;
    z-index: 1;
    color: #000;
    font-weight: 500;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: wpbingofont;
}
body i.slick-arrow.fa-angle-left {
    left: -15px;
}
body i.slick-arrow.fa-angle-right {
    right: -15px;
}
body i.slick-arrow.fa-angle-left:before {
    content: "\e91a";
}
body i.slick-arrow.fa-angle-right:before {
    content: "\e91b";
}
.loading:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
}
.page-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}
.page-preloader .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.page-preloader .loader div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: loader-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.page-preloader .loader div:nth-child(2) {
    animation-delay: -1s;
}
.slick-slider {
    overflow: hidden;
}
.slick-wrap {
    position: relative;
}
.slick-wrap:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
}
.slick-dotted.slick-slider {
    margin-bottom: 0;
}
.slick-sliders .slick-list {
    margin: 0 -15px;
}
.slick-sliders.fullwidth .slick-list {
    margin: 0;
}
.slick-sliders .slick-list .slick-slide {
    padding: 0 15px;
}
.no-space > .slick-wrap .slick-sliders .slick-list .slick-slide {
    padding: 0;
}
.slick-slide,
.slick-slide img,
.slick-sliders .slick-list,
.slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
ul.slick-dots {
    bottom: 30px;
    list-style-type: none;
    position: unset;
}
ul.slick-dots li {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 6px;
    width: 9px;
    height: 9px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.slick-dots li,
.slick-dots li button {
    height: 20px;
    width: 20px;
    cursor: pointer;
}
ul.slick-dots li button {
    font-size: 0;
    width: 9px;
    height: 9px;
    background: rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
}
ul.slick-dots li.slick-active button {
    background: #000;
}
.color-white ul.slick-dots li.slick-active button {
    background: #fff;
}
.scroll-image {
    position: relative;
}
.star-rating {
    overflow: hidden;
    position: relative;
    width: 85px;
    height: 1.2em;
    line-height: 1.2em;
    display: inline-block;
    font-family: icomoon;
    font-size: 12px;
}
.star-rating:before {
    content: "\e904\e904\e904\e904\e904";
    color: #e1e1e1;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    letter-spacing: 6px;
}
.star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
}
.star-rating span:before {
    content: "\e904\e904\e904\e904\e904";
    top: 0;
    position: absolute;
    left: 0;
    color: #fcad02;
    letter-spacing: 6px;
}
.quantity {
    width: auto;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1;
}
.quantity .minus,
.quantity .plus {
    background: #fff;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    vertical-align: text-top;
    overflow: visible;
    text-decoration: none;
    cursor: pointer;
    line-height: 13px;
    font-size: 18px;
    color: #868686;
    border: 1px solid #e5e5e5;
    text-align: center;
    width: 45px;
    height: 38px;
}
.quantity2 .minus,
.quantity2 .plus {
    background: #fff;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    vertical-align: text-top;
    overflow: visible;
    text-decoration: none;
    cursor: pointer;
    line-height: 13px;
    font-size: 18px;
    color: #868686;
    border: 1px solid #e5e5e5;
    text-align: center;
    width: 26px;
    height: 26px;
}
.quantity input.qty {
    float: left;
    width: 45px;
    height: 38px;
    text-align: center;
    padding: 0;
    -moz-appearance: textfield;
}
.quantity2 input.qty {
    float: left;
    width: 26px;
    height: 25px;
    text-align: center;
    padding: 0;
    -moz-appearance: textfield;
}
.cart-product-added {
    background: #000;
    line-height: 1.5;
    box-shadow: unset;
    color: #fff;
    top: 20px;
    left: auto;
    right: 20px;
    padding: 15px 25px;
    font-weight: 400;
    position: fixed;
    z-index: 999999;
}
.cart-product-added > div {
    display: inline-block;
}
.cart-product-added > div:before {
    display: inline-block;
    content: "\e91d";
    font-family: wpbingofont;
    margin-right: 10px;
}
.product-table .tr-price .amount {
    font-size: 20px;
    color: #000;
}
.product-table tbody tr td {
    border-left: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
    padding: 20px !important;
    background: #fff !important;
}
.product-table tbody tr td:first-child {
    background: #f5f5f5 !important;
    font-size: 16px;
    color: #000 !important;
    font-weight: 500 !important;
}
.product-table tbody tr.tr-add-to-cart td a {
    color: #fff;
    display: inline-block;
    line-height: 45px;
    background: #000;
    padding: 0 30px;
    font-weight: 500;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.product-table tbody tr.tr-add-to-cart td a:hover {
    background: rgba(0, 0, 0, 0.75);
}
.product-table .tr-price del {
    font-size: 0;
}
.product-table .tr-price del .amount {
    font-size: 16px;
    color: #868686;
}
.product-table .tr-price ins {
    text-decoration: none;
}
.product-table .tr-price ins .amount {
    color: #ff4545;
}
.woosc_table .tr-price .amount {
    font-size: 20px;
    color: #000;
}
.products-loadmore {
    text-align: center;
}
.products-loadmore .loadmore {
    min-width: 140px;
    letter-spacing: normal;
    height: 40px;
}
.products-loadmore .loadmore span {
    animation: none;
    font-size: 14px;
    display: inline-block;
    text-transform: inherit;
    position: relative;
    top: -2px;
}
.products-loadmore .loadmore i {
    font-size: 16px;
    margin-left: 5px;
}
.products-loadmore .loadmore.loading:before {
    content: none;
}
.products-loadmore .loadmore.loading .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
}
.products-loadmore .loadmore.loading .lds-ellipsis div {
    position: absolute;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.products-loadmore .loadmore:hover .lds-ellipsis div {
    background: #fff;
}
.products-loadmore .loadmore.loading .lds-ellipsis div:first-child {
    left: 12px;
    animation: i 0.6s infinite;
}
.products-loadmore .loadmore.loading .lds-ellipsis div:nth-child(2) {
    left: 12px;
    animation: k 0.6s infinite;
}
.products-loadmore .loadmore.loading .lds-ellipsis div:nth-child(3) {
    left: 36px;
    animation: k 0.6s infinite;
}
.products-loadmore .loadmore.loading .lds-ellipsis div:nth-child(4) {
    left: 60px;
    animation: j 0.6s infinite;
}
.products-loadmore .loadmore.loading span {
    opacity: 0;
    visibility: hidden;
}
.products-loadmore .loadmore.loading i {
    animation: 2s linear 0s normal none infinite running o;
    opacity: 1;
    visibility: visible;
}
.pagination {
    margin-top: 20px;
}
.pagination ul {
    padding-left: 0;
    list-style: none;
    text-align: center;
    width: 100%;
}
.pagination ul li {
    display: inline-block;
    margin: 0 3px;
}
.pagination ul li span,
.pagination ul li a {
    display: table-cell;
    padding: 0;
    float: none;
    width: 40px;
    height: 40px;
    line-height: normal;
    text-indent: 1px;
    text-align: center;
    vertical-align: middle;
    color: #868686;
    font-size: 15px;
    border: 1px solid #e5e5e5;
    -webkit-transition: none;
    transition: none;
    text-transform: uppercase;
}
.pagination ul li a:hover,
.pagination ul li span.current {
    color: #fff;
    background: #000;
    border-color: #000;
}
.pagination ul li a.prev,
.pagination ul li a.next {
    font-size: 0;
    color: #868686;
}
.pagination ul li a.prev:hover,
.pagination ul li a.next:hover {
    color: #fff;
}
.pagination ul li a.prev:before,
.pagination ul li a.next:before {
    font-size: 20px;
    font-family: eleganticons;
}
.pagination ul li a.prev:before {
    content: "\34";
}
.pagination ul li a.next:before {
    content: "\35";
}
.back-top {
    width: 40px;
    height: 40px;
    line-height: 49px;
    text-align: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999999;
    color: #000;
    border: 2px solid #000;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.back-top:hover {
    cursor: pointer;
    background: #000;
    color: #fff;
    border-color: #000;
}
.back-top.button-show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}
.back-top i {
    font-size: 26px;
    display: block;
    line-height: 35px;
}
.left-sidebar {
    padding-right: 30px;
}
.right-sidebar {
    padding-left: 30px;
}
.sidebar .block {
    margin-bottom: 50px;
}
.sidebar .block:last-child {
    margin-bottom: 0;
}
.sidebar .block .block-title h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 20px;
    text-transform: uppercase;
}

/*===================================================================
  2. Animate
  ====================================================================*/
@keyframes e {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        opacity: 0;
    }
}
@keyframes i {
    0% {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
@keyframes j {
    0% {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}
@keyframes k {
    0% {
        transform: translate(0);
    }
    to {
        transform: translate(24px);
    }
}
@keyframes l {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
@keyframes n {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}
@keyframes o {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}

@keyframes remove-spinner {
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes remove-spinner {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes animation-horizontal {
    16.65% {
        transform: translateX(8px);
    }
    33.3% {
        transform: translateX(-6px);
    }
    49.95% {
        transform: translateX(4px);
    }
    66.6% {
        transform: translateX(-2px);
    }
    83.25% {
        transform: translateX(1px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes loader-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/*========================================================================
  3. Header
  =========================================================================*/
.site-header {
    margin-bottom: 0;
    position: relative;
    z-index: 10;
    background: transparent;
}
.site-header.absolute {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}
.site-header.bottom-border {
    border-bottom: 1px solid #e5e5e5;
}
.site-header.bg-black {
    background: #000;
}
.site-header .header-mobile {
    padding: 20px 7.5px;
}
.site-header .header-mobile .section-container > .row {
    align-items: center;
}
.site-header .header-mobile .navbar-toggle {
    border: 0;
    background: transparent;
    padding: 0;
}
.site-header .header-mobile .navbar-toggle:before {
    content: "\e908";
    font-family: wpbingofont;
    font-size: 20px;
    color: #000;
}
.site-header.color-white .header-mobile .navbar-toggle:before {
    color: #fff;
}
.site-header .header-mobile .site-logo {
    text-align: center;
    margin: 0;
}
.site-header .site-logo img {
    max-width: 100px;
    width: 100%;
}
.site-header.header-v4 .header-center {
    padding-top: 10px;
}
.site-header .header-mobile .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.site-header .header-right {
    position: relative;
    color: #8d8d8d;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.site-header.header-v1 .header-desktop,
.site-header.header-v3 .header-desktop {
    padding: 30px 0;
}
.site-header.header-v2 .header-desktop {
    padding: 20px 0;
}
.site-header.header-v2.large-height .header-desktop {
    padding: 30px 0;
}
.site-header.header-v4 .header-top {
    padding: 35px 0;
    border-bottom: 1px solid #202020;
}
.site-header .header-wrapper {
    position: relative;
}
.site-header .header-wrapper.bg-black {
    background: #000;
}
.site-header.header-v4 .header-wrapper {
    padding: 15px 0;
}
.site-header .header-wrapper .section-container > .row {
    align-items: center;
}
.site-header .header-left > * {
    float: left;
}
.site-header .site-logo img {
    max-width: 100px;
    width: 100%;
}
.site-header .text-center {
    position: unset;
}
.site-header .header-menu {
    position: unset;
}
.site-header .header-mobile .header-mobile-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    padding: 15px 15px 10px;
    align-items: center;
    box-shadow: 0 1px 12px 2px hsl(0deg 0% 56% / 30%);
}
.site-header .header-mobile .header-mobile-fixed > div {
    flex: 1;
    text-align: center;
}
.site-header .header-mobile .header-mobile-fixed i {
    font-size: 20px;
}

/**
   * 3.1. Header Topbar
   */
#header-topbar {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
}
#header-topbar.topbar-v1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
#header-topbar,
#header-topbar a,
#header-topbar a:hover {
    color: #000;
}
#header-topbar .address,
#header-topbar .email,
#header-topbar .phone,
#header-topbar .ship {
    display: inline-block;
}
#header-topbar .address i,
#header-topbar .email i,
#header-topbar .phone i,
#header-topbar .ship i {
    margin-right: 10px;
    font-size: 15px;
    position: relative;
    top: 3px;
    color: #000;
}
#header-topbar .email {
    position: relative;
    margin-left: 15px;
    padding-left: 20px;
}
#header-topbar .email:before {
    position: absolute;
    content: "";
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    width: 1px;
    height: 18px;
    top: calc(50% - 9px);
}
#header-topbar .topbar-right {
    text-align: right;
}
#header-topbar #topbar_menu {
    list-style: none;
}
#header-topbar .topbar-right > * {
    display: inline-block;
    padding: 0 15px 0 0;
}
#header-topbar .topbar-right > :last-child {
    padding-right: 0;
}
#header-topbar #topbar-menu {
    list-style: none;
    margin-bottom: 0;
}
#header-topbar #topbar-menu li {
    display: inline-block;
    padding-right: 18px;
    margin-right: 13px;
    position: relative;
}
#header-topbar #topbar-menu li:last-child {
    margin-right: 0;
    padding-right: 0;
}
#header-topbar #topbar-menu li:before {
    position: absolute;
    content: "";
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    width: 1px;
    height: 18px;
    top: calc(50% - 9px);
}
#header-topbar #topbar-menu li:last-child:before {
    content: none;
}
#header-topbar .topbar-right > * a,
#header-topbar .topbar-right > * a:hover i {
    color: #000;
}

/**
   * 3.2. Header Menu
   */
.site-menu-wrapper .navbar-default {
    border: none;
    background: transparent;
}
.site-navigation {
    display: inline-block;
    vertical-align: top;
    float: none;
}
.site-header.header-v3 .header-desktop .site-navigation {
    margin-left: 60px;
}
.site-navigation ul {
    padding: 0;
    list-style: none;
    float: none;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    margin: 0;
}
.site-navigation ul > li.level-0 {
    position: static;
    display: table;
    float: left;
    padding: 0 9px;
    line-height: 24px;
    z-index: 999999;
}
.site-navigation ul > li.level-0:first-child {
    padding-left: 0;
}
.site-navigation ul > li.level-0:last-child {
    padding-right: 0;
}
.site-navigation ul li {
    text-align: left;
}
.site-navigation ul > li.level-0.current-menu-ancestor > a,
.site-navigation ul > li.level-0.current-menu-item > a,
.site-navigation ul > li.level-0.current_page_item > a,
.site-navigation ul > li.level-0:hover > a {
    color: #000;
}
.color-white .site-navigation ul > li.level-0.current-menu-ancestor > a,
.color-white .site-navigation ul > li.level-0.current-menu-item > a,
.color-white .site-navigation ul > li.level-0.current_page_item > a,
.color-white .site-navigation ul > li.level-0:hover > a {
    color: #fff;
}
.site-navigation ul > li.level-0.menu-item-has-children > a:before {
    content: "";
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    left: 0;
}
.site-navigation ul > li.level-0.menu-item-has-children > a {
    position: relative;
}
.site-navigation ul > li.level-0 > a {
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
    background-color: transparent;
    color: #000;
    display: table;
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
.color-white .site-navigation ul > li.level-0 > a {
    color: #fff;
}
.site-navigation ul > li.level-0 > a > span {
    position: relative;
    display: inline-block;
}
.site-navigation ul > li.level-0.current-menu-ancestor > a > span:before,
.site-navigation ul > li.level-0.current-menu-item > a > span:before,
.site-navigation ul > li.level-0.current_page_item > a > span:before,
.site-navigation ul > li.level-0:hover > a > span:before {
    opacity: 1;
    visibility: visible;
    width: 100%;
}
.site-navigation ul > li.level-0 > a > span:before {
    bottom: 0;
    left: 0;
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #000;
    opacity: 0;
    visibility: hidden;
}
.color-white .site-navigation ul > li.level-0 > a > span:before {
    background: #fff;
}
.site-navigation ul > li.level-0.menu-item-has-children > a:after {
    content: "\e92e";
    font-family: feather;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -1px;
    font-weight: 400;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.site-navigation ul > li.level-0:hover.menu-item-has-children > a:after {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}
.site-navigation ul > li.level-0 .sub-menu {
    display: block;
    min-width: 225px;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    z-index: 999999;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.1s linear, transform 0.1s linear;
    transition: opacity 0.1s linear, transform 0.1s linear;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
}
.site-navigation ul > li.level-0.align-center .sub-menu {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
    width: 500px;
    padding: 30px 40px;
    min-width: auto;
}
.site-navigation ul > li.level-0.mega-menu.mega-menu-fullwidth > .sub-menu {
    width: 750px;
}
.site-header.header-v2 .site-navigation ul > li.level-0 > .sub-menu {
    margin-top: 25px;
}
.site-header.header-v2.large-height
    .site-navigation
    ul
    > li.level-0
    > .sub-menu {
    margin-top: 35px;
}
.site-header.header-v1.relative .site-navigation ul > li.level-0 > .sub-menu {
    margin-top: 30px;
}
.site-header.header-v3.relative .site-navigation ul > li.level-0 > .sub-menu {
    margin-top: 41px;
}
.site-header.header-v4 .site-navigation ul > li.level-0 > .sub-menu {
    margin-top: 0;
}
.site-header.absolute .site-navigation ul > li.level-0 > .sub-menu {
    margin-top: 30px;
}
.site-navigation ul div.sub-menu,
.site-navigation ul ul.sub-menu {
    padding: 22px 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.site-navigation ul > li.level-0 ul.sub-menu {
    padding: 20px 0;
}
.site-navigation ul > li.level-0 ul.sub-menu li {
    padding: 4px 30px;
    position: relative;
}
.site-navigation ul > li.level-0 ul.sub-menu li:first-child {
    padding-top: 0;
}
.site-navigation ul > li.level-0 ul.sub-menu li:last-child {
    padding-bottom: 0;
}
.site-navigation ul > li.level-0 ul.sub-menu li a {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #868686;
    display: block;
    position: relative;
}
.site-navigation
    ul
    > li.level-0
    ul.sub-menu
    li.menu-item-has-children
    > a:after {
    content: "\e930";
    font-family: feather;
    font-size: 14px;
    position: absolute;
    right: 0;
}
.site-navigation ul > li.level-0 ul.sub-menu li ul.sub-menu {
    top: -26px;
    left: 100%;
}
.site-navigation ul > li.level-0 ul.sub-menu li:first-child ul.sub-menu {
    top: -30px;
}
.site-navigation ul > li.level-0 ul.sub-menu li a:hover {
    color: #000;
}
.site-navigation .menu-item-has-children:hover > .sub-menu {
    opacity: 1 !important;
    visibility: visible !important;
    -webkit-transform: translate(0);
    transform: translate(0);
    display: block;
    box-shadow: 3px 3px 45px 0 rgba(0, 0, 0, 0.05);
}


.site-navigation ul div.sub-menu .menu-section {
    margin-bottom: 30px;
}
.site-navigation ul div.sub-menu .menu-section:last-child {
    margin-bottom: 0;
}
.site-navigation ul div.sub-menu .sub-menu-title {
    display: block;
    font-size: 18px;
    margin-bottom: 18px;
    text-transform: capitalize;
    padding-bottom: 10px !important;
    position: relative;
}
.site-navigation ul div.sub-menu .sub-menu-title:before {
    position: absolute;
    bottom: -1px;
    width: 40px;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    content: "";
    left: 0;
}
.site-navigation ul div.sub-menu ul.menu-list li {
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: inherit;
}
.site-navigation ul div.sub-menu ul.menu-list li:first-child {
    padding-top: 0;
}
.site-navigation ul div.sub-menu ul.menu-list li:last-child {
    padding-bottom: 0;
}
.site-navigation ul div.sub-menu ul.menu-list li a {
    position: relative;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #868686;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: inherit;
}
.site-navigation ul div.sub-menu ul.menu-list li a:hover {
    color: #000;
}
.menu-full .menu-lines {
    position: relative;
    display: block;
    width: 20px;
    height: 12px;
    cursor: pointer;
}
.menu-full .menu-lines .line-general {
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background: #000;
    -webkit-transition: width 0.3s ease, top 0.3s ease;
    transition: width 0.3s ease, top 0.3s ease;
}
.menu-full .menu-lines .line-general.line-1 {
    top: 0;
    width: 20px;
}
.menu-full .menu-lines .line-general.line-2 {
    top: 5px;
    width: 10px;
}
.menu-full .menu-lines .line-general.line-3 {
    top: 10px;
    width: 20px;
}
.menu-full .site-navigation {
    position: fixed;
    width: 100%;
    left: 0;
    background: #fff;
    top: 0;
    padding: 200px 0 65px;
    height: 100vh;
    overflow: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 10;
    text-align: center;
}
.menu-full .site-navigation.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.menu-full .site-navigation #main-navigation {
    display: inline-block;
    position: relative;
}
.menu-full .site-navigation ul > li.level-0 {
    padding: 0 40px;
    position: relative;
    line-height: 24px;
}
.menu-full .site-navigation ul > li.level-0:after {
    position: absolute;
    content: "";
    background: #e5e5e5;
    right: 0;
    top: -5px;
    width: 1px;
    height: 54px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.menu-full .site-navigation ul > li.level-0:last-child:after {
    content: none;
}
.menu-full .site-navigation ul > li.level-0 > a {
    font-size: 30px;
    text-transform: none;
    font-weight: 400;
}
.menu-full .site-navigation ul > li.level-0.menu-item-has-children > a:after {
    content: none;
}
.menu-full .site-navigation ul > li.level-0 > a > span {
    display: inline;
    padding-bottom: 10px;
}
.menu-full .site-navigation ul > li.level-0 > .sub-menu {
    box-shadow: none;
    margin-top: 0;
    min-width: 300px;
}
.menu-full .site-navigation ul > li.level-0 ul.sub-menu li {
    padding: 4px 0;
}
.menu-full .close-menu-wrap {
    position: relative;
}
.menu-full .close-menu-full {
    position: absolute;
    z-index: 9;
    top: -100px;
    left: 50%;
    cursor: pointer;
    width: 28px;
    height: 28px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.menu-full .close-menu-full:after,
.menu-full .close-menu-full:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 14px;
    top: 50%;
    left: calc(50% - 7px);
    margin-top: -1px;
    background-color: #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.menu-full .close-menu-full:before {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(95deg);
    transform: rotate(95deg);
}
.menu-full .close-menu-full:after {
    transform-origin: 50% 50%;
}
.menu-full .close-menu-full:hover:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.menu-full .close-menu-full:hover:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/**
   * 3.3. Header Login
   */
.site-header .header-page-link {
    text-align: right;
}
.site-header .header-page-link .login-header {
    font-size: 14px;
    position: relative;
}
.site-header .header-page-link > :first-child {
    margin-left: 0;
}
.site-header .header-page-link > * {
    display: inline-block;
    margin-left: 19px;
    vertical-align: middle;
}
.site-header .header-page-link .login-header > a {
    position: relative;
    top: 0;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-right: 40px;
    letter-spacing: 2px;
}
.site-header.color-white .header-page-link .login-header > a {
    color: #fff;
}
.site-header .header-page-link .login-header.icon > a {
    padding-right: 0;
}
.site-header .header-page-link .login-header > a:after {
    position: absolute;
    content: "";
    background: #cbcbcb;
    width: 18px;
    height: 1px;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.site-header .header-page-link .login-header.icon > a:after {
    content: none;
}
.site-header .header-page-link .login-header > a.black:after {
    background: #000;
}
.form-login-register {
    /* display: none; */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
}
.site-header .header-page-link .login-header .icon-user {
    font-size: 20px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    top: 3px;
}
.site-header .header-page-link .login-header .icon-user:hover {
    top: -1px;
}
.form-login-register.active {
    display: block;
}
.form-login-register .box-form-login {
    width: 100%;
    max-width: 510px;
    background: #fff;
    margin: auto;
    position: absolute;
    /* left: calc(50% - 255px); */
    left: 50%;

    top: 50%;
    padding: 30px;
    -webkit-transform: translateY(-50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.form-login-register .box-form-login .active-login {
    text-align: right;
    position: absolute;
    z-index: 9;
    right: 16px;
    top: 15px;
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #e5e5e5;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom-color: transparent;
}
.form-login-register .box-form-login .active-login:after,
.form-login-register .box-form-login .active-login:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 14px;
    top: 50%;
    left: calc(50% - 7px);
    margin-top: -1px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.form-login-register .box-form-login .active-login:before {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(95deg);
    transform: rotate(95deg);
}
.form-login-register .box-form-login .active-login:after {
    transform-origin: 50% 50%;
}
.form-login-register .box-form-login .active-login:hover {
    background: #e5e5e5;
}
.form-login-register .box-form-login .active-login:hover:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.form-login-register .box-form-login .active-login:hover:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.form-login-register .box-content {
    position: relative;
    border: 1px solid #e5e5e5;
    /* overflow-x: hidden; */
    height: 100%;
    /* overflow-y: auto; */
}
.form-login-register .box-content > div {
    position: relative;
    z-index: 3;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
.form-login-register .box-content > div.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.form-login-register .box-content > div > form {
    padding: 20px 30px 30px;
    position: relative;
}
.form-login-register .box-content > div.active .login {
    display: block !important;
}
.form-login-register .box-form-login h2 {
    text-align: center;
    position: relative;
    font-size: 22px;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-weight: 600;
}
.form-login-register .box-form-login .content .username {
    margin-bottom: 15px;
    position: relative;
}
.form-login-register .box-form-login .content input {
    width: 100%;
    height: 55px;
    line-height: 55px;
}
.form-login-register .box-form-login .content .email input,
.form-login-register .box-form-login .content .password input,
.form-login-register .box-form-login .content .username input {
    padding: 0 20px;
}
.form-login-register .box-form-login .content .email,
.form-login-register .box-form-login .content .password,
.form-login-register .box-form-login .content .username {
    margin-bottom: 15px;
}
.form-login-register .box-form-login .rememberme-lost {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 17px;
    margin-bottom: 10px;
}
.form-login-register .box-form-login .rememberme-lost .rememberme {
    margin-right: 10px;
    position: relative;
}
.form-login-register .box-form-login .rememberme-lost .rememberme input {
    display: none;
}
.form-login-register .box-form-login .rememberme-lost .inline {
    color: #000;
    font-weight: 500;
    cursor: pointer;
}
.form-login-register .box-form-login .rememberme-lost .inline:before {
    width: 16px;
    height: 16px;
    content: "";
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 2px solid #000;
    margin-right: 5px;
    position: relative;
    top: 3px;
    z-index: 2;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
    width: 16px;
    height: 16px;
    content: "";
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    top: 3px;
    border: 4px solid #fff;
    left: 0;
    background: #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.form-login-register
    .box-form-login
    .rememberme-lost
    .rememberme
    input:checked
    + label:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.form-login-register .box-form-login .rememberme-lost .lost_password {
    font-weight: 500;
}
.form-login-register .box-form-login .rememberme-lost .lost_password a {
    display: inline-block;
    position: relative;
}
.form-login-register .box-form-login .rememberme-lost .lost_password a:before {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #000;
}
.form-login-register
    .box-form-login
    .rememberme-lost
    .lost_password
    a:hover:before {
    background: #000;
    width: 50%;
}
.form-login-register .box-form-login .button-login {
    position: relative;
}
.form-login-register .box-form-login .button-login input[type="submit"],
.form-login-register .box-form-login .button-register input[type="submit"] {
    background: #000;
    border: 0;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.1em;
}
.form-login-register .box-form-login .button-login input[type="submit"]:hover,
.form-login-register
    .box-form-login
    .button-register
    input[type="submit"]:hover {
    background: rgba(0, 0, 0, 0.75);
}
.form-login-register .box-form-login .button-next-login,
.form-login-register .box-form-login .button-next-reregister {
    background: #b9b9b9;
    border: 0;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    height: 55px;
    line-height: 55px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-weight: 500;
    letter-spacing: 0.1em;
}
.form-login-register .box-form-login .button-next-login:hover,
.form-login-register .box-form-login .button-next-reregister:hover {
    background: #000;
}
.form-login-register .box-content .form-register {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
.form-login-register .box-content .form-register.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

/**
   * 3.4. Header Cart
   */
.site-header .header-mobile .ruper-topcart {
    margin: 0 10px;
}
.site-header .header-mobile .mini-cart {
    text-align: right;
}
.site-header .header-mobile .mini-cart .cart-icon {
    position: relative;
    top: -2px;
}
.site-header.color-white .header-mobile .mini-cart .cart-icon {
    color: #fff;
}
.site-header .header-mobile .mini-cart .cart-icon .icons-cart {
    display: inline-block;
    position: relative;
}
.site-header .header-mobile .mini-cart .cart-icon i {
    font-size: 20px;
    position: relative;
    top: 4px;
}
.site-header .header-mobile .mini-cart .cart-count {
    position: absolute;
    top: -7px;
    right: -17px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 12px;
}
.site-header.color-white .header-mobile .mini-cart .cart-count {
    color: #000;
    background: #fff;
}
.site-header .header-page-link > .ruper-topcart {
    margin-left: 20px;
}
.ruper-topcart.dropdown .mini-cart {
    position: relative;
}
.mini-cart .remove-cart-shadow {
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}
.site-header .header-page-link .mini-cart .cart-icon {
    position: relative;
}
.site-header.color-white .header-page-link .mini-cart .cart-icon {
    color: #fff;
}
.site-header .header-page-link .mini-cart .cart-icon .icons-cart {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
}
.site-header .header-page-link .mini-cart .cart-icon .icons-cart:hover {
    color: #000;
}
.site-header.color-white
    .header-page-link
    .mini-cart
    .cart-icon
    .icons-cart:hover {
    color: #fff;
}
.site-header .header-page-link .mini-cart .cart-icon .icons-cart:hover i {
    top: -3px;
}
.site-header .header-page-link .mini-cart .cart-icon i,
.site-header .header-page-link .search-box .search-toggle {
    font-size: 20px;
    position: relative;
    top: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.site-header .header-page-link .mini-cart .cart-icon .icons-cart .cart-count {
    position: absolute;
    top: -7px;
    right: -17px;
    width: 17px;
    height: 17px;
    line-height: 17px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 12px;
}
.site-header.color-white
    .header-page-link
    .mini-cart
    .cart-icon
    .icons-cart
    .cart-count {
    background: #fff;
    color: #000;
}
.mini-cart .cart-popup {
    text-align: left;
    width: 380px;
    padding: 24px 20px;
    border: 1px solid #f3f3f3;
    background: #fff;
    position: absolute;
    color: #000;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px) !important;
    transform: translateY(20px) !important;
    z-index: 10001;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    left: auto !important;
    right: 0 !important;
    top: 60px !important;
    will-change: unset !important;
    box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.2);
}
.site-header.header-v2 .header-desktop .mini-cart .cart-popup {
    top: 50px !important;
}
.site-header.header-v3.relative .mini-cart .cart-popup {
    top: 66px !important;
}
.site-header.header-v4 .header-top .mini-cart .cart-popup {
    top: 65px !important;
}
.site-header.header-v2.large-height .header-desktop .mini-cart .cart-popup {
    top: 60px !important;
}
.ruper-topcart.dropdown .mini-cart.show .cart-popup {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
}
.ruper-topcart.dropdown .remove-cart,
.ruper-topcart.dropdown .top-total-cart {
    display: none;
}
.ruper-topcart.dropdown .mini-cart.show .remove-cart-shadow {
    display: block;
}
.mini-cart .cart-popup .cart-list {
    list-style: none;
    margin: 0;
    padding: 0 0 10px 0;
    max-height: 335px;
    overflow-y: auto;
}
.mini-cart .cart-popup .cart-list li.empty {
    text-align: center;
    position: relative;
    padding-top: 55px;
    font-size: 18px;
    color: #000;
}
.mini-cart .cart-popup .cart-list li.empty:before {
    content: "\e914";
    font-family: wpbingofont;
    position: absolute;
    top: 0;
    font-size: 50px;
    line-height: 1;
    left: calc(50% - 25px);
    color: #000;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop:hover:before {
    width: 100%;
}
.mini-cart .cart-popup .cart-list li.empty span {
    display: block;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 28px;
    margin-top: 5px;
    position: relative;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop:before {
    position: absolute;
    content: "";
    background: #000;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop i {
    font-size: 18px;
    margin-left: 5px;
    position: relative;
    top: 3px;
}
.mini-cart .cart-popup .mini-cart-item {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}
.mini-cart .cart-popup .mini-cart-item a.remove {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    line-height: 17px;
    color: #000;
    text-indent: -1px;
}
.mini-cart .cart-popup .mini-cart-item a.remove:hover {
    color: #fff;
    background: #000;
    border-color: #000;
}
.mini-cart .cart-popup .mini-cart-item a {
    color: #868686;
}
.mini-cart .cart-popup .product-image {
    display: inline-block;
    width: 78px;
    float: left;
    margin-right: 12px;
}
.mini-cart .cart-popup .product-image img {
    width: 100%;
    height: auto;
    display: block;
}
.mini-cart .cart-popup a.product-name {
    color: #000 !important;
    padding-right: 20px;
    width: calc(100% - 20px);
    display: block;
    line-height: 1.3;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 15px;
}
.mini-cart .cart-popup .quantity {
    font-weight: 500;
    color: #868686;
    margin: 0;
    margin-top: 0;
}
.mini-cart .cart-popup .remove {
    position: absolute;
    right: 5px;
    top: 2px;
}
.mini-cart .cart-popup .total-cart {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 26px;
    position: relative;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #000;
    padding-top: 15px;
}
.mini-cart .cart-popup .total-cart .total-price {
    font-size: 20px;
}
.mini-cart .cart-popup .free-ship {
    border-top: 1px solid #000;
    margin-top: 15px;
    padding-top: 20px;
    font-size: 16px;
}
.mini-cart .cart-popup .free-ship strong {
    text-decoration: underline;
}
.mini-cart .cart-popup .free-ship .total-percent,
.mini-cart .cart-popup .free-ship .total-percent .percent {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.mini-cart .cart-popup .free-ship .total-percent {
    position: relative;
    width: 100%;
    background: #e9e9e9;
    height: 5px;
    margin: 10px 0 30px;
}
.mini-cart .cart-popup .free-ship .total-percent .percent {
    position: absolute;
    height: 100%;
    left: 0;
    background: #000;
    top: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.mini-cart .cart-popup .free-ship .total-percent .percent:before {
    content: "\e90b";
    position: absolute;
    right: 0;
    font-family: icomoon;
    top: -14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 30px;
    padding: 0 2px;
    background: #fff;
    line-height: 1;
}
.mini-cart .cart-popup .buttons {
    display: flex;
    margin-bottom: 10px;
    margin-top: 13px;
    width: 100%;
}
.mini-cart .cart-popup .buttons .button {
    width: calc(50% - 4px);
    margin: 5px 0;
    display: block;
    padding: 15px;
    font-weight: 500;
    letter-spacing: 0.1em;
    overflow: hidden;
    position: relative;
    padding: 10px 15px;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    background: #a0a0a0;
    -webkit-transition: unset;
    transition: unset;
    display: inline-block;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    margin: 0 5px;
    border: 0;
}
.mini-cart .cart-popup .buttons .button.view-cart {
    background: #000;
    border-color: #000;
}
.mini-cart .cart-popup .buttons .button.view-cart:before {
    background: #6d6d6d;
}
.mini-cart .cart-popup .buttons .button:before {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    bottom: 0;
    top: auto;
}
.mini-cart .cart-popup .buttons .button:first-child {
    margin-left: 0;
}
.mini-cart .cart-popup .buttons .button:last-child {
    margin-right: 0;
}

/**
   * 3.5. Header Search
   */
.search-box {
    cursor: pointer;
    text-align: center;
}
.site-header .header-page-link .search-box .search-toggle {
    color: #000;
}
.site-header.color-white .header-page-link .search-box .search-toggle {
    color: #fff;
}
.site-header .header-page-link .mini-cart .cart-icon i,
.site-header .header-page-link .search-box .search-toggle {
    font-size: 20px;
    position: relative;
    top: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.site-header .header-page-link .search-box .search-toggle:hover {
    color: #000;
    top: -3px;
}
.site-header.color-white .header-page-link .search-box .search-toggle:hover {
    color: #fff;
}
.site-header .header-search-form {
    flex: 1;
    margin-right: 50px;
}
.site-header .header-search-form .search-from {
    width: 100%;
    height: 50px;
    display: flex;
    position: relative;
    border: 1px solid #000;
    max-width: 660px;
    margin-left: auto;
}
.site-header .header-search-form .search-from .search-box {
    text-align: left;
    flex: 1;
}
.site-header .header-search-form .search-from .search-box input[type="text"] {
    border: 0;
    line-height: 50px;
    width: 100%;
    padding: 0 25px;
    font-size: 15px;
}
.site-header .header-search-form .result-search-products-content {
    position: absolute;
    background: #fff;
    width: 100%;
    top: calc(100% + 10px);
    left: 0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 1px 12px 2px hsla(0, 0%, 56%, 0.3);
    display: none;
    z-index: 10;
}
.site-header .header-search-form .result-search-products {
    display: none;
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;
    padding: 20px;
    overflow-x: hidden;
}
.site-header .header-search-form .result-search-products .items-search {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.site-header .header-search-form .result-search-products .item-search {
    list-style: none;
    padding: 15px 0;
    display: table;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
}
.site-header
    .header-search-form
    .result-search-products
    .item-search:first-child {
    padding-top: 0;
}
.site-header
    .header-search-form
    .result-search-products
    .item-search:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}
.site-header
    .header-search-form
    .result-search-products
    .item-search
    .item-image {
    display: table-cell;
    width: 70px;
}
.site-header
    .header-search-form
    .result-search-products
    .item-search
    .item-content {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
}
.site-header
    .header-search-form
    .result-search-products
    .item-search
    .item-content
    a {
    font-weight: 500;
    text-transform: capitalize;
}
.site-header .header-search-form .result-search-products .price {
    font-size: 14px;
}
.site-header .header-search-form .result-search-products.loading {
    padding: 30px;
}
.site-header .header-search-form .result-search-products.loading:before {
    position: absolute;
    left: 50%;
    top: calc(50% - 17px);
    display: inline-block;
    content: "\f110";
    width: auto;
    height: auto;
    color: #000;
    margin-left: 0;
    font-family: FontAwesome;
    background-color: transparent !important;
    background: none;
    font-size: 20px;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
}
.site-header .header-search-form .search-from #search-submit {
    color: #fff;
    font-size: 0;
    position: relative;
    line-height: 50px;
    padding: 0 40px;
    background: #000;
    text-align: center;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.site-header .header-search-form .search-from #search-submit:hover {
    background: rgba(0, 0, 0, 0.75);
}
.site-header .header-search-form .search-from #search-submit i {
    font-size: 18px;
    line-height: 46px;
}
.search-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    color: #000;
    right: 0;
    z-index: 999999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.overlay-open {
    opacity: 1 !important;
    visibility: visible !important;
}
.wrapper-open {
    transform: translateX(0%) !important;
}
.search-overlay.search-visible {
    opacity: 1;
    visibility: visible;
}
.search-overlay > .close-search {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    cursor: unset !important;
}
.search-overlay .wrapper-search {
    max-width: 560px;
    margin-left: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    padding: 40px 50px;
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
.search-overlay.search-visible .wrapper-search {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.search-overlay .search-from {
    margin: auto;
    display: flex;
    position: relative;
}
.search-overlay .search-from .search-box {
    text-align: left;
    flex: 1;
    position: relative;
    cursor: unset;
}
.search-overlay .search-from .search-box #searchsubmit {
    position: absolute;
    top: 6px;
    background: transparent;
    color: #000;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    border: 0;
    padding: 0;
    left: 0;
}
.search-overlay .search-from .search-box #searchsubmit > .icon-search {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.search-overlay .search-from .search-box input[type="text"] {
    border: 0;
    padding: 0;
    line-height: 40px;
    width: 100%;
    color: #000;
    font-size: 16px;
    border-bottom: 2px solid #e5e5e5;
    margin-bottom: 50px;
    font-weight: 500;
    padding: 0 70px 0 30px;
    -webkit-transition: max-width 1s ease;
    transition: max-width 1s ease;
    transition-delay: 0.3s;
}
.search-overlay.search-visible .search-from .search-box input[type="text"] {
    max-width: 100%;
}
.search-overlay.search-visible
    .search-from
    .search-box
    input[type="text"]::placeholder {
    color: #000;
}
.search-overlay .search-from .search-box input[type="text"]:focus,
.search-overlay
    .search-from
    .search-box
    input[type="text"]:not(:placeholder-shown) {
    border-color: #000;
}
.search-overlay .search-from .search-box .search-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    overflow: hidden;
}
.search-overlay .search-from .search-box .close-search {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 0 10px 10px;
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    background: #fff;
    cursor: pointer;
}
.search-overlay
    .search-from
    .search-box
    input:placeholder-shown
    + .search-top
    .close-search {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
.search-overlay .search-from .search-box .content-menu_search label {
    color: #acacaa;
    font-size: 18px;
    margin: 0 0 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
}
.search-overlay .search-from .search-box .content-menu_search ul {
    padding: 0;
    list-style: none;
}
.search-overlay .search-from .search-box .content-menu_search ul li a:hover {
    text-decoration: underline;
}

/**
   * 3.6. Header Wishlist
   */
.site-header .header-page-link .wishlist-box {
    font-size: 20px;
    position: relative;
    top: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.site-header .header-page-link .wishlist-box:hover {
    color: #000;
    top: -3px;
}
.site-header.color-white .header-page-link .wishlist-box > a {
    color: #fff;
}
.site-header .header-page-link .wishlist-box .count-wishlist {
    position: absolute;
    top: -5px;
    right: -17px;
    width: 17px;
    height: 17px;
    line-height: 17px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 11px;
}
.site-header.color-white .header-page-link .wishlist-box .count-wishlist {
    background: #fff;
    color: #000;
}

/*========================================================================
  4. Breadcrumbs
  =========================================================================*/
.site-main .page-title {
    background-image: url(../src/assets/frontimg/site-header.jpg);
    padding: 145px 15px 0 15px;
    margin: 0 0 70px;
    font-size: 20px;
    padding-bottom: 50px;
    background-position: top;
    position: relative;
    text-align: center;
}
.site-main .page-title .content-title-heading {
    display: inline-flex;
    position: relative;
    align-items: center;
}
.site-main .page-title .content-title-heading .text-title-heading {
    text-transform: capitalize;
    margin-top: 0;
    font-size: 50px;
    text-align: center;
}
.site-main .page-title .breadcrumbs {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.site-main .page-title .breadcrumbs a {
    color: #868686;
    position: relative;
}
.site-main .page-title .breadcrumbs .delimiter {
    margin: 0 10px;
    color: #868686;
}
.site-main .page-title .breadcrumbs .delimiter:before {
    position: relative;
    content: "";
    display: inline-block;
    height: 12px;
    width: 1px;
    top: 2px;
    background: #868686;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
}

/*========================================================================
  5. Block
  =========================================================================*/
/**
   * 5.1. Block Slider
   */
.block-sliders {
    position: relative;
}
.block-sliders .slick-sliders .slick-list .slick-slide {
    padding: 0;
}
.block-sliders .slick-sliders .slick-list {
    margin: 0;
}
.block-sliders .item-content {
    position: relative;
}
.block-sliders .item-content .content-image img {
    width: 100%;
    height: 100px;
    min-height: 100vh;
    object-fit: cover;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.block-sliders.auto-height .item-content .content-image img {
    height: auto;
    min-height: auto;
}
.block-sliders .item-content .item-info {
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: -100px;
    padding: 0 7.5px;
    transition-delay: 0.6s;
    display: flex;
}
.block-sliders .slick-current .item-content .item-info {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}
.block-sliders .item-content .item-info.vertical-middle {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.block-sliders .item-content .item-info.vertical-bottom {
    bottom: 0;
}
.block-sliders .item-content .item-info.horizontal-start {
    justify-content: flex-start;
}
.block-sliders .item-content .item-info.horizontal-center {
    justify-content: center;
}
.block-sliders .item-content .item-info.horizontal-end {
    justify-content: flex-end;
}
.block-sliders .item-content .item-info .title-slider {
    font-size: 80px;
    font-weight: 300;
    margin: 0px 0px 10px -5px;
}
.block-sliders.color-white .item-content .item-info .title-slider {
    color: #fff;
}
.block-sliders .item-content .item-info .subtitle-slider {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 3px;
    margin-bottom: 5px;
}
.block-sliders.color-white .item-content .item-info .subtitle-slider {
    color: #fff;
}
.block-sliders .item-content .item-info .description-slider {
    color: #000000;
    font-size: 20px;
    margin-bottom: 30px;
}
.block-sliders.color-white .item-content .item-info .description-slider {
    color: #fff;
}
.block-sliders .item-content .item-info .button-slider {
    padding: 0px 40px;
}
.block-sliders .slider-bg-image {
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translateX(-50%);
}
.block-sliders .slider-bg-image .image-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.block-sliders .slick-dots {
    padding: 0px 90px 60px 0px;
    text-align: right;
    position: absolute;
    bottom: 0;
    z-index: 99;
}
.block-sliders.nav-vertical .slick-dots {
    padding: 0;
    top: calc(50% - 65px);
    flex-flow: column;
    display: inline-flex;
    right: 85px;
    left: auto;
    width: auto;
}
.block-sliders.nav-center .slick-dots {
    padding: 0px 0px 60px 0px;
    text-align: center;
}
.block-sliders.nav-left .slick-dots {
    padding: 0px 0px 70px 125px;
    text-align: left;
}
.block-sliders .slick-dots li {
    margin: 0 15px;
}
.block-sliders.nav-vertical .slick-dots li {
    margin: 15px 0;
}
.block-sliders .slick-dots li button {
    margin: auto;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #000;
    font-size: 0;
    transition: all 0.2s ease;
    padding: 0;
    position: relative;
}
.block-sliders.color-white .slick-dots li button {
    background: #fff;
}
.block-sliders .slick-dots li button:after,
.block-sliders .slick-dots li button:before {
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    top: calc(50% - 11px);
    left: calc(50% - 11px);
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: #000;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.block-sliders .slick-dots li.slick-active button:before {
    border-top-color: #000;
    border-right-color: #000;
    border-bottom-color: #000;
    transition: border-top-color 2s linear, border-right-color 2s linear 1s,
        border-bottom-color 2s linear 3s;
    color: #000;
    opacity: 0.75;
}
.block-sliders.color-white .slick-dots li.slick-active button:before {
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;
}
.block-sliders .slick-dots li button:after {
    border: 0 solid transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    top: calc(50% - 11px);
    left: calc(50% - 11px);
}
.block-sliders .slick-dots li.slick-active button:after {
    border-top: 2px solid #000;
    border-left-width: 2px;
    border-right-width: 2px;
    transform: rotate(270deg);
    transition: transform 5s linear 0s, border-left-width 0s linear 5s,
        -webkit-transform 5s linear 0s;
}
.block-sliders.color-white .slick-dots li.slick-active button:after {
    border-top: 2px solid #fff;
}
.block-sliders.layout-2 .item-content .item-info {
    padding: 0% 0% 0% 14.5%;
}
.block-sliders.layout-2 .item-content .item-info .title-slider {
    font-size: 80px;
    line-height: 80px;
    margin: 0 0 40px 0;
}
.block-sliders.layout-2 .slick-current .item-content .content {
    text-align: center;
}
.block-sliders.layout-3 .item-content .item-info .title-slider {
    font-size: 60px;
    font-weight: 300;
    line-height: 70px;
    margin: 0px 0px 15px -5px;
}
.block-sliders.layout-3 .item-content .item-info .description-slider {
    margin-bottom: 35px;
}
.block-sliders.layout-4 .item-content .item-info {
    padding: 0px 55px 50px 55px;
}
.block-sliders.layout-4 .item-content .item-info .title-slider {
    font-size: 40px;
    font-weight: 300;
    margin: 0px 0px 7px -2px;
}
.block-sliders.layout-4 .item-content .item-info .content {
    opacity: 0;
    visibility: hidden;
}
.block-sliders.layout-4 .slick-current .item-content .item-info .content {
    opacity: 1;
    visibility: visible;
}
.block-sliders.layout-4 .item-content .item-info.animation-top .content {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transition-delay: 0.6s;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
}
.block-sliders.layout-4
    .slick-current
    .item-content
    .item-info.animation-top
    .content {
    -webkit-transform: translate(0);
    transform: translate(0);
}
.block-sliders.layout-4 .item-content .item-info .button-slider {
    font-size: 15px;
    padding: 0 0 3px 0;
    background-color: transparent;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: normal;
}
.block-sliders.layout-4 .item-content .item-info .button-slider:hover {
    color: #000;
    background-color: transparent;
}
.block-sliders.layout-4 .slick-dots {
    padding: 0px 20px 30px 20px;
    position: absolute;
    bottom: 0;
    z-index: 10;
}
.block-sliders.layout-5 .item-content {
    display: flex;
}
.block-sliders.layout-5 .item-content .item-info {
    width: 50%;
    padding: 0 5px 0 0;
    position: relative;
    display: block;
    opacity: 1;
    visibility: visible;
    -webkit-transition: none;
    transition: none;
    margin-top: 0;
    transition-delay: initial;
}
.block-sliders.layout-5 .item .item-info .content {
    background: #e5e8e4;
    height: 100%;
}
.block-sliders.layout-5 .item:nth-child(odd) .item-info .content {
    background: #e8e4e4;
}
.block-sliders.layout-5 .item:nth-child(3n + 1) .item-info .content {
    background: #f2ede0;
}
.block-sliders.layout-5 .item .item-info .content .content-wrap {
    top: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 125px;
}
.block-sliders.layout-5 .item-content .item-info .title-slider {
    font-size: 60px;
    font-weight: 300;
    line-height: 70px;
    margin: 0px 0px 35px 0;
}
.block-sliders.layout-5 .item-content .item-info .button-slider {
    line-height: 46px;
}
.block-sliders.layout-5 .item-content .content-image {
    width: 50%;
    padding: 0 0 0 5px;
}
.block-sliders.layout-6 .item-content .item-info {
    padding: 0px 15px 135px 15px;
}
.block-sliders.layout-6 .item-content .item-info .title-slider {
    font-size: 70px;
    margin: 0px 0px 25px 0;
}
.block-sliders.layout-6 .item-content .item-info .subtitle-slider {
    letter-spacing: 2px;
    margin-bottom: 0;
}
.block-sliders.layout-7 .item-content .item-info {
    padding: 0% 21.5% 3% 0%;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
    font-size: 70px;
    font-weight: 300;
    margin: 0px 0px 7px -5px;
}
.block-sliders.layout-8 .item-content .item-info {
    padding: 0% 0% 5% 15%;
}
.block-sliders.layout-8 .item-content .item-info .subtitle-slider {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 5px;
}
.block-sliders.layout-8 .slick-current .item-content .content {
    text-align: center;
}
.block-sliders.layout-8 .item-content .item-info .title-slider {
    font-size: 70px;
    font-weight: 300;
    margin: 0 0 30px 0;
}
.block-sliders.layout-9 .item-content {
    display: flex;
}
.block-sliders.layout-9 .item-content .item-info {
    width: 50%;
    padding: 0;
    position: relative;
    display: block;
    opacity: 1;
    visibility: visible;
    -webkit-transition: none;
    transition: none;
    margin-top: 0;
    transition-delay: initial;
}
.block-sliders.layout-9.diff-col .item-content .item-info {
    width: 34.5%;
}
.block-sliders.layout-9 .item .item-info .content {
    background: #fff;
    height: 100%;
}
.block-sliders.layout-9 .item .item-info .content.background-1 {
    background: #efeae7;
}
.block-sliders.layout-9 .item .item-info .content.background-2 {
    background: #e7efe7;
}
.block-sliders.layout-9 .item .item-info .content.background-3 {
    background: #efe7ee;
}
.block-sliders.layout-9 .item .item-info .content .content-wrap {
    top: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 250px;
}
.block-sliders.layout-9.diff-col .item .item-info .content .content-wrap {
    padding-left: 85px;
}
.block-sliders.layout-9 .item-content .item-info .title-slider {
    font-size: 55px;
    font-weight: 300;
    line-height: 60px;
    margin: 0px 0px 20px 0;
}
.block-sliders.layout-9 .item-content .item-info .description-slider {
    margin-bottom: 30px;
}
.block-sliders.layout-9 .item-content .item-info .button-slider {
    line-height: 46px;
}
.block-sliders.layout-9 .item-content .content-image {
    width: 50%;
    padding: 0;
}
.block-sliders.layout-9.diff-col .item-content .content-image {
    width: 65.5%;
}
.block-sliders.layout-9.nav-left .slick-dots {
    padding: 0px 0px 70px 250px;
}
.block-sliders.layout-9.diff-col.nav-left .slick-dots {
    padding-left: 70px;
}
.block-sliders.layout-12 .item-content .item-info .subtitle-slider {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 3px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.block-sliders.layout-12 .item-content .item-info .title-slider {
    font-size: 70px;
    font-weight: 300;
    margin: 0px 0px 35px 0px;
}
.block-sliders.layout-15 .item-content .item-info .title-slider {
    font-size: 65px;
    font-weight: 300;
    margin: 0px 0px 15px 0px;
}
.block-sliders.layout-15 .item-content .item-info .button-slider {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0px 0px 5px 0px;
    fill: #000000;
    color: #000000;
}

/**
   * 5.2. Block Banners
   */
.block-banners.banners-effect .banners > div a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}
.block-banners.banners-effect .banners > div img {
    backface-visibility: hidden;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-banners.banners-effect .banner-image:hover img {
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}
.block-banners.layout-1 .section-column.left {
    width: 41.1%;
}
.block-banners.layout-1 .section-column.right {
    width: 58.9%;
}
.block-banners.layout-2 .section-column.left {
    width: 58.9%;
}
.block-banners.layout-2 .section-column.right {
    width: 41.1%;
}
.block-banners.layout-4 .row {
    margin: 0 -30px;
}
.block-banners.layout-4 .row > div {
    padding: 0 30px;
}
.block-banners.layout-4 .row > div:nth-child(2) .block-widget-banner {
    margin-top: 210px;
}
.block-banners.layout-5 .row {
    margin: 0 -40px;
}
.block-banners.layout-5 .row > div {
    padding: 0 40px;
}
.block-banners.layout-5 .row > div:nth-child(2) .block-widget-banner {
    margin-top: 185px;
}
.block-banners .block-widget-banner {
    position: relative;
}
.block-banners .block-widget-banner .banners .banner-image a {
    display: block;
}
.block-banners .block-widget-banner .banners .banner-image img {
    width: 100%;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
    justify-content: center;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
}
.block-banners .block-widget-banner.layout-1 .button {
    font-size: 20px;
    line-height: 105px;
    padding: 0px 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    min-width: 188px;
    text-align: center;
    font-weight: 400;
    letter-spacing: normal;
}
.block-banners .block-widget-banner.layout-1 .button:after {
    position: relative;
    content: "\e90a";
    font-family: icomoon;
    font-size: 20px;
    right: 0;
    top: 3px;
    padding-left: 10px;
}
.block-banners .block-widget-banner.layout-1:hover .button {
    opacity: 1;
    visibility: visible;
}
.block-banners .block-widget-banner.layout-2 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 50px 60px 50px;
}
.block-banners
    .block-widget-banner.layout-2
    .banner-wrapper-infor
    .title-banner {
    font-size: 38px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
}
.block-banners
    .block-widget-banner.layout-2
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 25px;
}
.block-banners .block-widget-banner.layout-2 .banner-wrapper-infor .button {
    padding: 0px 35px;
}
.block-banners .block-widget-banner.layout-3 .banner-wrapper-infor {
    justify-content: center;
    display: flex;
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 15px 85px 15px;
}
.block-banners
    .block-widget-banner.layout-3
    .banner-wrapper-infor
    .title-banner {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 12px;
    margin-top: 0;
}
.block-banners
    .block-widget-banner.layout-3
    .banner-wrapper-infor
    .title-banner
    span {
    font-weight: 500;
    border-bottom: 1px solid #000;
}
.block-banners
    .block-widget-banner.layout-3
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 0;
}
.block-banners .block-widget-banner.layout-4 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 40px 50px 40px;
}
.block-banners .block-widget-banner.layout-4 .banner-wrapper-infor.text-center {
    justify-content: center;
}
.block-banners .block-widget-banner.layout-4 .banner-wrapper-infor .link-title {
    display: block;
}
.block-banners
    .block-widget-banner.layout-4
    .banner-wrapper-infor
    .title-banner {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 3px;
    margin-top: 0;
}
.block-banners .block-widget-banner.layout-4 .button {
    font-size: 14px;
}
.block-banners .block-widget-banner.layout-5 .banners .banner-image {
    margin-bottom: 25px;
}
.block-banners .block-widget-banner.layout-5 .banner-wrapper-infor.text-center {
    text-align: center;
}
.block-banners
    .block-widget-banner.layout-5
    .banner-wrapper-infor
    .title-banner {
    font-size: 30px;
    margin-bottom: 5px;
    margin-top: 0;
}
.block-banners
    .block-widget-banner.layout-5
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 15px;
}
.block-banners .block-widget-banner.layout-5 .button {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 2px;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    transform: translateY(0);
    top: 0;
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 115px 0px 0px 50px;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
    justify-content: flex-end;
    padding: 110px 105px 0px 0px;
}
.block-banners
    .block-widget-banner.layout-6
    .banner-wrapper-infor
    .title-banner {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
}
.block-banners .block-widget-banner.layout-7 .banners .banner-image {
    margin-bottom: 30px;
}
.block-banners
    .block-widget-banner.layout-7
    .banner-wrapper-infor
    .title-banner {
    font-size: 38px;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 0;
}
.block-banners
    .block-widget-banner.layout-7
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 30px;
}
.block-banners .block-widget-banner.layout-8 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 15px 50px 150px;
}
.block-banners .right .block-widget-banner.layout-8 .banner-wrapper-infor {
    padding: 0px 70px 50px 70px;
}
.block-banners .block-widget-banner.layout-8 .banner-wrapper-infor .info {
    overflow: hidden;
}
.block-banners .block-widget-banner.layout-8 .banner-wrapper-infor .content {
    position: relative;
    padding-bottom: 50px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-banners
    .block-widget-banner.layout-8
    .banner-wrapper-infor
    .content:after {
    position: absolute;
    content: "";
    background: #000;
    width: 62px;
    height: 1px;
    bottom: 0;
    left: 0;
}
.block-banners
    .block-widget-banner.layout-8
    .banner-wrapper-infor
    .banner-image-subtitle {
    color: #000000;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.block-banners
    .block-widget-banner.layout-8
    .banner-wrapper-infor
    .title-banner {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 0;
    margin-left: -2px;
}
.block-banners
    .right
    .block-widget-banner.layout-8
    .banner-wrapper-infor
    .title-banner {
    font-size: 30px;
}
.block-banners
    .block-widget-banner.layout-8:hover
    .banner-wrapper-infor
    .content {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.block-banners .block-widget-banner.layout-9 .banners .banner-image {
    margin-bottom: 25px;
}
.block-banners
    .block-widget-banner.layout-9
    .banner-wrapper-infor
    .title-banner {
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 0;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 50px 90px 50px;
}
.block-banners
    .block-widget-banner.layout-10
    .banner-wrapper-infor
    .subtitle-banner {
    font-size: 20px;
    margin-bottom: 10px;
}
.block-banners
    .block-widget-banner.layout-10
    .banner-wrapper-infor
    .title-banner {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 25px;
}
.block-banners.background-img {
    background-image: url(../src/assets/frontimg/banner/banner-bg.jpg);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
    justify-content: center;
    display: flex;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
}
.block-banners
    .block-widget-banner.layout-11
    .banner-wrapper-infor
    .title-banner {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 15px 45px 45px;
}
.block-banners
    .block-widget-banner.layout-12
    .banner-wrapper-infor
    .title-banner {
    color: #ffffff;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 0;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
    justify-content: flex-start;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 15px 0px 90px;
}
.block-banners
    .block-widget-banner.layout-13
    .banner-wrapper-infor
    .title-banner {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 0;
}
.block-banners
    .block-widget-banner.layout-13
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 30px;
}
.block-banners .block-widget-banner.layout-14 .banner-infor {
    background: #303235;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-content: center;
    align-items: center;
}
.block-banners .block-widget-banner.layout-14 .banner-wrapper-infor {
    padding-left: 135px;
}
.block-banners
    .block-widget-banner.layout-14
    .banner-wrapper-infor
    .title-banner {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.block-banners
    .block-widget-banner.layout-14
    .banner-wrapper-infor
    .banner-image-description {
    color: #fff;
    margin-bottom: 15px;
}
.block-banners .block-widget-banner.layout-14 .banner-wrapper-infor .button {
    color: #fff;
    font-size: 13px;
    letter-spacing: 2px;
}
.block-banners
    .block-widget-banner.layout-14
    .banner-wrapper-infor
    .button:before {
    background: #fff;
}
.block-banners .block-widget-banner.layout-15 .banner-wrapper-infor {
    justify-content: center;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
}
.block-banners
    .block-widget-banner.layout-15
    .banner-wrapper-infor
    .link-title {
    display: block;
}
.block-banners
    .block-widget-banner.layout-15
    .banner-wrapper-infor
    .title-banner {
    color: #fff;
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 25px;
    margin-top: 0;
}
.block-banners .block-widget-banner.layout-15 .banner-wrapper-infor .button {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    fill: #fff;
    color: #fff;
    padding-bottom: 8px;
}
.block-banners
    .block-widget-banner.layout-15
    .banner-wrapper-infor
    .button:before {
    height: 2px;
    background: #fff;
}
.block-banners .block-widget-banner.layout-16 .banner-infor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0px 30px 0 80px;
}
.block-banners .block-widget-banner.layout-16 .banner-infor.background-1 {
    background-color: #94856c;
}
.block-banners .block-widget-banner.layout-16 .banner-infor.background-2 {
    background-color: #4c5365;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .banner-icon {
    font-size: 65px;
    margin-bottom: 16px;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .banner-icon:hover {
    animation-name: animation-horizontal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .banner-icon
    svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
    fill: #fff;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .title-banner {
    color: #fff;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 25px;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .banner-image-description {
    color: #fff;
    font-size: 16px;
}
.block-banners .block-widget-banner.layout-16 .banner-wrapper-infor .button {
    border-color: #fff;
    color: #fff;
    border-width: 2px;
    margin-top: 30px;
}
.block-banners
    .block-widget-banner.layout-16
    .banner-wrapper-infor
    .button:hover {
    border-color: #000;
}
.block-banners.slider .slick-sliders {
    overflow: hidden;
}
.block-banners.slider .slick-list {
    margin: 0 -25px;
    padding: 0 21.5% !important;
}
.block-banners.slider .item {
    padding: 0 25px;
}
.block-banners.slider .banner-image img {
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-banners.slider .item:hover .banner-image img {
    filter: brightness(0.8);
}
.block-banners.slider .item .banner-wrapper-infor {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    margin-top: 30px;
}
.block-banners.slider .item.slick-center .banner-wrapper-infor {
    opacity: 1;
    visibility: visible;
}
.block-banners.slider .item.slick-center .banner-wrapper-infor .title-banner {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 0;
}
.block-banners.slider
    .item.slick-center
    .banner-wrapper-infor
    .banner-image-description {
    margin-bottom: 25px;
}
.block-banners.slider .item.slick-center .banner-wrapper-infor .button {
    text-transform: uppercase;
    line-height: 48px;
    letter-spacing: 3px;
}
.block-banners.slider .slick-arrow {
    font-family: wpbingofont;
    opacity: 1;
    visibility: visible;
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-size: 15px;
    border: 0;
    background: #fff;
    top: 60%;
    -webkit-transform: none;
    transform: none;
    margin-top: 0;
}
.block-banners.slider .slick-arrow.fa-angle-left {
    left: 20.5% !important;
    margin-left: -5px;
}
.block-banners.slider .slick-arrow.fa-angle-right {
    right: 20.5% !important;
    margin-right: -5px;
}
.block-banners.slider .slick-arrow:hover {
    background: #000;
    color: #fff;
}
.block-banners.slider .slick-wrap {
    position: static;
}

/**
   * 5.3. Block Products
   */
.block-products.slider .content-product-list {
    position: relative;
}
.block-products.slider .content-product-list i.slick-arrow {
    font-size: 20px;
    top: 40%;
}
.block-products .btn-all {
    text-align: center;
}
.block-products .btn-all a {
    padding: 0 40px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-products .nav-tabs {
    margin-bottom: 40px;
    border-bottom: none;
    display: block;
    text-align: center;
}
.block-products .nav-tabs.align-left {
    text-align: left;
}
.block-products .nav-tabs .nav-item {
    display: inline-block;
    padding: 0 25px;
    margin: 0;
    position: relative;
}
.block-products .nav-tabs .nav-item:first-child {
    padding-left: 0;
}
.block-products .nav-tabs .nav-item:last-child {
    padding-right: 0;
}
.block-products .nav-tabs .nav-item:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: #e1e1e1;
    width: 1px;
    height: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.block-products .nav-tabs .nav-item:last-child:before {
    content: none;
}
.block-products .nav-tabs.layout-2 .nav-item:before {
    content: none;
}
.block-products .nav-tabs .nav-item .nav-link {
    font-size: 22px;
    color: #868686;
    display: inline-block;
    position: relative;
    padding: 0 0 5px 0;
    border: none;
}
.block-products .nav-tabs.small-text .nav-item .nav-link {
    font-size: 18px;
}
.block-products .nav-tabs.layout-2 .nav-item .nav-link {
    font-size: 18px;
    text-transform: uppercase !;
    font-weight: 500;
    letter-spacing: 1.5px;
    padding-bottom: 10px;
}
.block-products .nav-tabs .nav-item .nav-link:before {
    position: absolute;
    content: "";
    background: #000;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-products .nav-tabs.layout-2 .nav-item .nav-link:before {
    height: 2px;
}
.block-products .nav-tabs .nav-item:hover .nav-link,
.block-products .nav-tabs .nav-item .nav-link.active {
    color: #000;
}
.block-products .nav-tabs .nav-item:hover .nav-link:before,
.block-products .nav-tabs .nav-item .nav-link.active:before {
    width: 100%;
}
.block-products ul.products-list {
    padding-left: 0;
    list-style-type: none;
    padding-top: 5px;
}
.block-products ul.products-list li {
    display: flex;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #e1e1e1;
}
.block-products ul.products-list li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
.block-products ul.products-list li .product-image {
    flex: 0 0 80px;
}
.block-products ul.products-list li .product-content {
    padding-left: 15px;
}
.block-products ul.products-list li .product-content .product-title {
    font-size: 16px;
}

/**
   * 5.4. Block Product Categories
   */
.block-product-cats.slider .product-cats-list {
    position: relative;
}
.block-product-cats .item-product-cat-content {
    text-align: center;
}
.block-product-cats .item-product-cat-content .item-image {
    margin: 0px 0px 17px 0px;
}
.block-product-cats .item-product-cat-content .item-image:hover {
    animation-name: animation-horizontal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}
.block-product-cats .item-product-cat-content .item-image img {
    border: 1px solid #e6e6e6;
    width: 100%;
}
.block-product-cats.round-border .item-product-cat {
    padding: 0 42.5px;
}
.block-product-cats.round-border .item-product-cat-content .item-image img {
    border-radius: 50% 50% 50% 50%;
}
.block-product-cats
    .item-product-cat-content
    .product-cat-content-info
    .item-title {
    font-size: 18px;
    margin: 0px 0px 10px 0px;
}
.block-product-cats
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a {
    position: relative;
    padding-bottom: 5px;
}
.block-product-cats
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a:before {
    position: absolute;
    content: "";
    width: 0;
    height: 1px;
    background: #000;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-product-cats
    .item-product-cat-content:hover
    .product-cat-content-info
    .item-title
    a:before {
    width: 100%;
}
.block-product-cats.layout-2 .row {
    margin: 0 -30px;
}
.block-product-cats.layout-2 .row > div {
    padding: 0 30px;
}
.block-product-cats.layout-2 .row > div:nth-child(even) .cat-item {
    margin-top: 120px;
}
.block-product-cats.layout-2.items-equal .row > div:nth-child(even) .cat-item {
    margin-top: 0;
}
.block-product-cats.layout-2 .cat-item .cat-image a {
    display: block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}
.block-product-cats.layout-2 .cat-item .cat-image img {
    width: 100%;
    backface-visibility: hidden;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-product-cats.layout-2 .cat-item:hover .cat-image img {
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}
.block-product-cats.layout-2 .cat-item .cat-title {
    text-align: center;
    margin-top: 25px;
}
.block-product-cats.layout-2 .cat-item .cat-title h3 {
    font-size: 18px;
}
.block-product-cats.layout-3 .row {
    margin: 0 -30px;
}
.block-product-cats.layout-3 .row > div {
    padding: 0 30px;
}
.block-product-cats.layout-3 .cat-item .cat-image {
    margin-bottom: 25px;
}
.block-product-cats.layout-3 .cat-item .cat-image a {
    display: block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
    width: 100%;
    backface-visibility: hidden;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-product-cats.layout-3 .cat-item:hover .cat-image img {
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}
.block-product-cats.layout-3 .cat-item .cat-title {
    text-align: center;
}
.block-product-cats.layout-3 .cat-item .cat-title h3 {
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0;
}
.block-product-cats.layout-3 .center .cat-item:first-child {
    margin-top: 90px;
    margin-bottom: 60px;
}
.block-product-cats.layout-4
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a {
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 2px;
    background-color: #000;
    border: 1px solid #000;
    padding: 0px 15px;
    margin: 0;
}
.block-product-cats.layout-4
    .item-product-cat-content
    .product-cat-content-info
    .item-title:hover
    a {
    background-color: transparent;
    color: #000;
}
.block-product-cats.layout-4
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a:before {
    content: none;
}
.block-product-cats.layout-4 .item-product-cat-content .item-image:hover {
    animation-name: none;
}
.block-product-cats.layout-5 .product-cats-intro-wrap {
    align-content: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}
.block-product-cats.layout-5 .product-cats-intro-wrap .title {
    font-size: 40px;
    font-weight: 300;
    line-height: 45px;
    margin-bottom: 20px;
}
.block-product-cats.layout-5 .product-cats-intro-wrap .description {
    margin-bottom: 20px;
}
.block-product-cats.layout-5 .product-cats-intro-wrap .link a {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 2px;
    fill: #000;
    color: #000;
    padding: 0 0 8px 0;
    position: relative;
    text-transform: uppercase;
}
.block-product-cats.layout-5 .product-cats-intro-wrap .link a:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-product-cats.layout-5 .product-cats-intro-wrap .link a:hover:before {
    width: 50%;
}
.block-product-cats.layout-5 .item-product-cat-content {
    text-align: left;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image {
    margin-bottom: 12px;
}
.block-product-cats.layout-5
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a {
    font-size: 20px;
    margin-bottom: 5px;
}
.block-product-cats.layout-5
    .item-product-cat-content
    .product-cat-content-info
    .item-title
    a:before {
    left: 0;
    -webkit-transform: none;
    transform: none;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image:hover {
    animation-name: none;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
    border: none;
}
.block-product-cats.layout-5.slider i.slick-arrow {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #fff;
    font-size: 11px;
    top: 40%;
}
.block-product-cats.layout-5.slider i.slick-arrow.fa-angle-left {
    left: -18px;
}
.block-product-cats.layout-5.slider i.slick-arrow.fa-angle-right {
    right: -18px;
}
.block-product-cats.layout-5.slider i.slick-arrow:hover {
    background: #000;
    color: #fff;
}
.block-product-cats.layout-6 .cat-image {
    position: relative;
}
.block-product-cats.layout-6 .cat-image:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
    display: inline-block;
}
.block-product-cats.layout-6 .cat-image a {
    display: block;
    position: relative;
    overflow: hidden;
}
.block-product-cats.layout-6 .cat-item .cat-image img {
    width: 100%;
    backface-visibility: hidden;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-product-cats.layout-6 .cat-item:hover .cat-image img {
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}
.block-product-cats.layout-6 .cat-item .cat-title {
    justify-content: center;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0px 15px 0px 15px;
    z-index: 3;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
    font-size: 30px;
    font-weight: 300;
    fill: #ffffff;
    color: #ffffff;
    position: relative;
    padding-bottom: 3px;
    opacity: 0;
    visibility: hidden;
}
.block-product-cats.layout-6 .cat-item:hover .cat-title a,
.block-product-cats.layout-6 .cat-item:hover .cat-image:before {
    opacity: 1;
    visibility: visible;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
    position: absolute;
    content: "";
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-product-cats.layout-6 .cat-item .cat-title a:hover:before {
    width: 50%;
}
.block-product-cats.layout-7 .row {
    margin: 0 -47.5px;
}
.block-product-cats.layout-7 .row > div {
    padding: 0 47.5px;
}
.block-product-cats.layout-7 .cat-item {
    position: relative;
}
.block-product-cats.layout-7 .cat-item .cat-title {
    justify-content: center;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
}
.block-product-cats.layout-7 .cat-item .cat-title a {
    padding: 0px 15px 0px 15px;
    min-width: 135px;
    text-align: center;
    background: #fff;
}
.block-product-cats.layout-7 .cat-item .cat-title a:hover {
    background: #000;
    color: #fff;
}
.block-product-cats.layout-7 .cat-item .cat-title a h3 {
    font-size: 20px;
    margin-bottom: 0;
    line-height: 60px;
}
.block-product-cats.layout-7 .cat-item .cat-title a:hover h3 {
    color: #fff;
}
.block-product-cats.layout-7 .center .cat-item:first-child {
    margin-bottom: 55px;
}
.block-product-cats.layout-7 .cat-image a {
    display: block;
    position: relative;
    overflow: hidden;
}
.block-product-cats.layout-7 .cat-item .cat-image img {
    width: 100%;
    backface-visibility: hidden;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-product-cats.layout-7 .cat-item:hover .cat-image img {
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}
.block-product-cats ul {
    padding-left: 0;
    list-style-type: none;
}
.block-product-cats ul li {
    display: block;
    margin-bottom: 10px;
}
.block-product-cats ul li a {
    color: #868686;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.block-product-cats ul li.current a,
.block-product-cats ul li a:hover {
    color: #000;
}
.block-product-cats ul li .count {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 400;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #f1f1f1;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    display: block;
}

/**
   * 5.5. Block Video
   */
.block-video .section-column.left {
    width: 41.3%;
}
.block-video .section-column.right {
    width: 58.7%;
}
.block-video .left .block-widget-wrap {
    margin: 100px 0px -100px 0px;
}
.block-video .left .block-widget-video {
    position: relative;
    text-align: center;
}
.block-video .block-widget-video .video-thumb img {
    width: 100%;
}
.block-video .block-widget-video .video-wrap {
    position: absolute;
    top: calc(50% - 55px);
    width: 100%;
}
.block-video .block-widget-video .video-wrap .video {
    display: inline-block;
    width: 70px;
    height: 70px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 18px;
    line-height: 70px;
    text-indent: 3px;
    position: relative;
    cursor: pointer;
}
.block-video .block-widget-video .video-wrap .video:hover {
    background: #000;
    color: #fff;
}
.block-video .block-widget-video .video-wrap .video:hover:after,
.block-video .block-widget-video .video-wrap .video:hover:before {
    border-color: #000;
}
.block-video .block-widget-video .video-wrap .video:after,
.block-video .block-widget-video .video-wrap .video:before {
    content: "";
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    width: 70px;
    height: 70px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-animation-name: e;
    animation-name: e;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.block-video .block-widget-video .video-wrap .video:after {
    animation-delay: 1s;
}
.block-video .right .block-widget-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-content: center;
    align-items: center;
}
.block-video .right .block-widget-wrap .block-widget-video {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.block-video .block-widget-video .video-text {
    padding: 0 25px;
}
.block-video .block-widget-video .video-text .title {
    font-size: 50px;
    font-weight: 300;
    line-height: 55px;
    margin-bottom: 20px;
}
.block-video .block-widget-video .video-text .description {
    margin-bottom: 40px;
}
.block-video .content-video .modal-dialog {
    max-width: 800px;
}
.block-video .content-video .modal-dialog .modal-body {
    position: relative;
    padding: 0px;
}
.block-video .content-video .modal-dialog .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
}

/**
   * 5.6. Block Feature
   */
.block-feature .box {
    padding: 30px 0px 35px 0px;
    border: 1px solid #e4e4e4;
    text-align: center;
}
.block-feature.no-border .box {
    padding: 0;
    border: none;
}
.block-feature .box .box-icon {
    margin-bottom: 12px;
}
.block-feature .box .box-icon span {
    display: inline-block;
    line-height: 1;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: #818a91;
    font-size: 50px;
    text-align: center;
}
.block-feature .box .box-icon span:hover {
    color: #818a91;
    animation-name: animation-horizontal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}
.block-feature .box .box-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.block-feature .box .box-title {
    font-size: 20px;
}
.block-feature .box .box-description {
    margin-bottom: 0;
}
.block-feature.layout-2 .box {
    padding: 0;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    text-align: left;
}
.block-feature.layout-2 .box .box-icon {
    margin: 0 18px 0 0;
}
.block-feature.layout-2 .box .box-title {
    margin-bottom: 1px;
}
.block-feature.layout-3 .box {
    max-width: 350px;
    margin: 0 auto;
}
.block-feature.layout-3 .box .box-icon span {
    font-size: 80px;
}
.block-feature.layout-3 .box .box-title {
    margin-bottom: 10px;
}
.block-feature.layout-4 .row > div {
    border-right: 1px solid #dedede;
}
.block-feature.layout-4 .row > div:last-child {
    border-right: none;
}
.block-feature.layout-4 .box {
    padding: 0;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.block-feature.layout-4 .box .box-icon {
    margin: 0 10px 0 0;
    display: inline-flex;
}
.block-feature.layout-4 .box .box-icon span {
    font-size: 35px;
}
.block-feature.layout-4 .box .box-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
}

/**
   * 5.7. Block Posts
   */
.block-posts .posts-list {
    padding: 0;
    list-style: none;
}
.block-posts .post-item {
    display: flex;
    margin-bottom: 20px;
}
.block-posts .post-item:last-child {
    margin-bottom: 0;
}
.block-posts .post-item .post-image {
    display: inline-block;
    max-width: 90px;
    flex: 0 0 90px;
}
.block-posts .post-item .post-image img {
    max-width: 90px;
}
.block-posts .post-item .post-content {
    padding-left: 15px;
    margin-top: -2px;
}
.block-posts .post-item .post-content .post-title {
    font-size: 16px;
    margin: 0 0 1px;
}
.block-posts .post-item .post-content .post-time {
    display: inline-block;
    font-size: 14px;
    color: #868686;
}
.block-posts .post-item .post-content .post-time .post-comment:before {
    content: "|";
    display: inline-block;
    margin: 0 8px 0 5px;
    color: #e1e1e1;
}
.block-posts.layout-2 .post-image {
    margin-bottom: 18px;
    position: relative;
    overflow: hidden;
}
.block-posts.layout-2 .post-image img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.block-posts.layout-2 .post-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.block-posts.layout-2 .post-content {
    text-align: center;
}
.block-posts.layout-2 .post-content .post-categories {
    margin-bottom: 5px;
}
.block-posts.layout-2 .post-content .post-categories a {
    color: #868686;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.block-posts.layout-2 .post-content .post-title {
    font-size: 22px;
    margin-bottom: 12px;
    margin-top: 0;
}
.block-posts.white-bg .post .post-inner {
    background: #fff;
    margin-bottom: 30px;
}
.block-posts.white-bg .post .post-inner .post-content {
    padding: 0 10px 30px 10px;
}
.block-posts.layout-3 .post {
    margin-bottom: 30px;
    overflow: hidden;
}
.block-posts.layout-3 .post .post-inner {
    display: flex;
    align-items: center;
}
.block-posts.layout-3 .post-image {
    position: relative;
    overflow: hidden;
    flex: 0 0 235px;
}
.block-posts.layout-3 .post-image img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.block-posts.layout-3 .post-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.block-posts.layout-3 .post-content {
    padding-left: 50px;
}
.block-posts.layout-3 .post-content .post-categories {
    margin-bottom: 5px;
}
.block-posts.layout-3 .post-content .post-categories a {
    color: #868686;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.block-posts.layout-3 .post-content .post-title {
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 0;
}
.block-posts.layout-3 .post:first-child .post-inner {
    position: relative;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0.3),
        transparent
    );
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0, transparent);
}
.block-posts.layout-3 .post:first-child .post-image {
    max-height: 300px;
    flex: 0 0 auto;
}
.block-posts.layout-3 .post:first-child .post-image .post-thumbnail {
    max-width: 100%;
}
.block-posts.layout-3 .post:first-child .post-content {
    z-index: 9;
    position: absolute;
    bottom: 0;
    padding: 0 30px 40px;
    width: 100%;
    left: 0;
}
.block-posts.layout-3 .post:first-child .post-content .post-categories a {
    color: #fff;
}
.block-posts.layout-3 .post:first-child .post-content .post-title {
    margin-bottom: 0;
}
.block-posts.layout-3 .post:first-child .post-content .post-title a {
    color: #fff;
}
.block-posts.layout-3 .post:first-child .post-content .btn-read-more {
    display: none;
}
.block-posts ul.posts-list {
    padding-left: 0;
    list-style-type: none;
    padding-top: 5px;
}
.block-posts ul.posts-list li {
    display: flex;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #e1e1e1;
}
.block-posts ul.posts-list li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
.block-posts ul.posts-list li .post-image {
    flex: 0 0 80px;
}
.block-posts ul.posts-list li .post-content {
    padding-left: 15px;
}
.block-posts ul.posts-list li .post-content .post-title {
    font-size: 15px;
}

/**
   * 5.8. Block Lookbook
   */
.block-lookbook {
    position: relative;
}
.block-lookbook.layout-2 .lookbook-wrap {
    max-width: 630px;
}
.block-lookbook.layout-2 .lookbook-wrap.right {
    margin-left: auto;
}
.block-lookbook .background-overlay {
    background-color: #e8dad0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.block-lookbook .background-overlay.background-2 {
    background-color: #85786b;
}
.block-lookbook .lookbook-content {
    vertical-align: top;
    width: 100%;
}
.block-lookbook .lookbook-content .item {
    position: relative;
}
.block-lookbook .lookbook-content .item > img {
    width: 100%;
}
.block-lookbook .item-lookbook {
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    text-align: center;
    line-height: 35px;
    -webkit-transition: width 0.2s ease, height 0.2s ease;
    transition: width 0.2s ease, height 0.2s ease;
    display: inline-block;
    z-index: 5;
    text-indent: -1px;
    cursor: pointer;
}
.block-lookbook .item-lookbook:after,
.block-lookbook .item-lookbook:before {
    width: 40px;
    height: 40px;
    position: absolute;
    content: "";
    left: calc(50% - 17.5px);
    top: calc(50% - 17.5px);
    border: 1px solid #fcfbfe;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    z-index: -1;
    -webkit-animation-name: n;
    animation-name: n;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.block-lookbook .item-lookbook:after {
    animation-delay: 1.5s;
}
.block-lookbook .item-lookbook .number-lookbook {
    font-size: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #fff;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.block-lookbook .item-lookbook .number-lookbook:hover {
    background: #000;
    color: #fff;
}
.block-lookbook .item-lookbook .number-lookbook:before {
    content: "\e905";
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-family: icomoon;
    font-size: 15px;
    font-weight: 700;
    color: #000;
}
.block-lookbook .item-lookbook .number-lookbook:hover:before {
    color: #fff;
}
.block-lookbook .item-lookbook .content-lookbook {
    z-index: 9;
    padding: 10px;
    text-align: center;
    width: 225px;
    box-shadow: -1px 5px 5px 0 rgba(182, 41, 41, 0.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background: #fff;
    display: none;
    position: absolute;
}
.block-lookbook .item-lookbook:hover .content-lookbook {
    display: block;
}
.block-lookbook .item-lookbook .content-lookbook .content-lookbook-bottom {
    margin-top: 15px;
    padding-bottom: 10px;
}
.block-lookbook
    .item-lookbook
    .content-lookbook
    .content-lookbook-bottom
    .item-title {
    margin-bottom: 0;
    line-height: 25px;
    text-transform: capitalize;
}
.block-lookbook
    .item-lookbook
    .content-lookbook
    .content-lookbook-bottom
    .item-title
    a {
    color: #000;
    font-size: 16px;
    line-height: 25px;
}
.block-lookbook
    .item-lookbook
    .content-lookbook
    .content-lookbook-bottom
    .price {
    font-weight: 500;
}
.block-lookbook.layout-2 .lookbook-intro-wrap {
    margin-top: 60px;
    margin-bottom: 85px;
}
.block-lookbook .lookbook-intro-wrap .sub-title {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1;
}
.block-lookbook .lookbook-intro-wrap .title {
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.1;
}
.block-lookbook.color-white .lookbook-intro-wrap .sub-title,
.block-lookbook.color-white .lookbook-intro-wrap .title {
    color: #fff;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .title {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 10px;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .sub-title {
    font-size: 15px;
    color: #868686;
    line-height: 1.8;
    margin-bottom: 10px;
}
.block-lookbook .lookbook-intro-wrap .button {
    padding: 0px 35px;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .button {
    line-height: 1.8;
    font-weight: 400;
    letter-spacing: normal;
    font-size: 15px;
    padding: 0 0 3px 0;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .button:hover {
    color: #000;
    background-color: transparent;
}
.block-lookbook.layout-3 .background-overlay {
    background: #e5e8e4;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
    max-width: 705px;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    align-items: center;
}
.block-lookbook.layout-3 .lookbook-intro-wrap.m-l-0 {
    margin-left: 0;
}
.block-lookbook.layout-3 .lookbook-intro .title {
    font-size: 50px;
    font-weight: 300;
    line-height: 55px;
    margin-bottom: 20px;
}
.block-lookbook.layout-3 .lookbook-intro .description {
    font-size: 16px;
    width: 440px;
    max-width: 440px;
    margin-bottom: 30px;
}

/**
   * 5.9. Block Testimonial
   */
.block-testimonial {
    position: relative;
}
.block-testimonial .slick-sliders {
    max-width: 890px;
    margin: auto;
}
.block-testimonial.layout-2 .slick-sliders {
    max-width: none;
    margin: 0;
}
.block-testimonial .testimonial-content {
    text-align: center;
}
.block-testimonial.layout-2 .testimonial-content {
    text-align: left;
}
.block-testimonial .testimonial-content .item {
    padding: 0 15px;
}
.block-testimonial.layout-2 .testimonial-content .item {
    border: 1px solid #e9e9e9;
    padding: 23px 20px;
}
.block-testimonial .testimonial-content .item .rating {
    margin: 0 0 15px;
}
.block-testimonial.layout-2 .testimonial-content .item .rating {
    margin: 0;
}
.block-testimonial .testimonial-content .item .testimonial-excerpt {
    font-family: "EB Garamond", Sans-serif;
    font-size: 28px;
    font-style: italic;
    line-height: 38px;
    margin-bottom: 25px;
}
.block-testimonial.layout-2 .testimonial-content .item .testimonial-excerpt {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 1.8;
    margin-bottom: 20px;
}
.block-testimonial .testimonial-content .item .testimonial-image {
    display: flex;
    align-items: center;
}
.block-testimonial
    .testimonial-content
    .item
    .testimonial-image.image-position-top {
    flex-wrap: wrap;
}
.block-testimonial .testimonial-content .item .testimonial-image .thumbnail {
    margin: 0px 0px 20px 0px;
    width: 100%;
}
.block-testimonial.layout-2
    .testimonial-content
    .item
    .testimonial-image
    .thumbnail {
    margin: 0 16px 0 0;
    width: auto;
}
.block-testimonial
    .testimonial-content
    .item
    .testimonial-image
    .thumbnail
    img {
    margin: auto;
    width: 110px;
    border: 1px solid #ececec;
    padding: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.block-testimonial.layout-2
    .testimonial-content
    .item
    .testimonial-image
    .thumbnail
    img {
    margin: 0;
    width: 65px;
    border: none;
    padding: 0;
}
.block-testimonial .testimonial-content .item .testimonial-info {
    width: 100%;
}
.block-testimonial.layout-2 .testimonial-content .item .testimonial-info {
    width: auto;
}
.block-testimonial
    .testimonial-content
    .item
    .testimonial-info
    .testimonial-customer-name {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 0;
}
.block-testimonial.layout-2
    .testimonial-content
    .item
    .testimonial-info
    .testimonial-customer-name {
    font-size: 16px;
}
.block-testimonial.layout-2 .testimonial-content .item .testimonial-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px;
}
.block-testimonial.layout-2
    .testimonial-content
    .item
    .testimonial-icon
    .icon-quote {
    color: #dedede;
    font-size: 30px;
}
.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
    margin: 0;
    font-size: 22px;
    margin: 0 0 10px;
}
.block-testimonial .slick-arrow {
    font-size: 30px;
}
.block-testimonial.layout-2 .slick-arrow {
    font-size: 20px;
}
.block-testimonial.layout-1:hover .slick-arrow.fa-angle-left {
    left: 5px;
}
.block-testimonial.layout-1:hover .slick-arrow.fa-angle-right {
    right: 5px;
}

/**
   * 5.10. Block Intro
   */
.block-intro {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
}
.block-intro .intro-image img {
    margin-bottom: 40px;
}
.block-intro .intro-text {
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    margin-bottom: 25px;
    color: #000;
}

/**
   * 5.11. Block Newsletter
   */
.block-newsletter .sub-title {
    margin-bottom: 8px;
}
.block-newsletter .title {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 25px;
    color: #000;
}
.block-newsletter .newsletter-content {
    width: 100%;
    max-width: 580px;
    border: 0;
    margin: auto;
    display: flex;
}
.block-newsletter .newsletter-content .your-email {
    width: 100%;
}
.block-newsletter .newsletter-content input[type="email"] {
    width: 100%;
    line-height: 48px;
    height: 48px;
    border: 0;
    font-size: 15px;
    padding: 0 20px;
    background: #fff;
}
.block-newsletter .newsletter-content input[type="submit"] {
    border: 0;
    padding: 0 25px;
    background: #000;
    letter-spacing: 2px;
    position: relative;
    line-height: 48px;
    height: 48px;
    top: 0;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}
.block-newsletter .newsletter-content input[type="submit"]:hover {
    background: rgba(0, 0, 0, 0.75);
}
.block-newsletter .newsletter-text {
    margin-bottom: 22px;
}
.block-newsletter.layout-2 .newsletter-text {
    margin-bottom: 10px;
}
.block-newsletter .newsletter-title {
    font-size: 32px;
    font-weight: 300;
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: 15px;
}
.block-newsletter .newsletter-form {
    width: 100%;
    max-width: 580px;
    border: 0;
    margin: 0;
    display: flex;
}
.block-newsletter.layout-2 .newsletter-form {
    margin: auto;
    margin-right: 0;
}
.block-newsletter.layout-2.one-col .newsletter-form {
    margin-right: auto;
}
.block-newsletter.layout-2.one-col.align-left .newsletter-form {
    margin-left: 0;
    margin-right: 0;
}
.block-newsletter.layout-2.one-col .newsletter-title-wrap {
    text-align: center;
    margin-bottom: 20px;
}
.block-newsletter.layout-2.one-col.align-left .newsletter-title-wrap {
    text-align: left;
}
.block-newsletter .newsletter-form input[type="email"] {
    border: 1px solid #e8e8e8;
    line-height: 48px;
    height: 48px;
    padding: 0 20px;
    width: 100%;
}
.block-newsletter .newsletter-form input[type="email"].bg-white {
    background: #fff;
    border-color: #fff;
}
.block-newsletter.layout-2 .newsletter-form input[type="email"] {
    border: 0;
    border-bottom: 2px solid #000;
    padding: 0;
    height: 45px;
    line-height: 45px;
}
.block-newsletter.layout-2 .newsletter-form input[type="email"]::placeholder {
    color: #000;
}
.block-newsletter .newsletter-form .btn-submit {
    margin-left: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    background: #000;
    cursor: pointer;
}
.block-newsletter.layout-2 .newsletter-form .btn-submit {
    margin-left: 10px;
    background: transparent;
}
.block-newsletter .newsletter-form .btn-submit:hover {
    background: rgba(0, 0, 0, 0.75);
}
.block-newsletter.layout-2 .newsletter-form .btn-submit:hover {
    background: transparent;
}
.block-newsletter .newsletter-form .btn-submit:before {
    position: absolute;
    content: "\e906";
    font-family: icomoon;
    font-size: 20px;
    color: #fff;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    line-height: 1;
    z-index: 2;
}
.block-newsletter.layout-2 .newsletter-form .btn-submit:before {
    content: none;
}
.block-newsletter .newsletter-form .btn-submit input[type="submit"] {
    height: 48px;
    border: 0;
    font-size: 0;
    padding: 0 30px;
    background: transparent;
    color: transparent;
    z-index: 2;
    cursor: pointer;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
    border: 1px solid #fff;
}
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
    font-size: 12px;
    color: #000;
    border-bottom: 2px solid #000;
    padding: 0;
    font-weight: 500;
    line-height: 45px;
    height: 45px;
    letter-spacing: 2px;
}
.block-newsletter.layout-2
    .newsletter-form
    .btn-submit
    input[type="submit"]:hover {
    font-style: italic;
}

/**
   * 5.12. Block Image
   */
.block-image.slider .item-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-image.slider .item-image img {
    max-height: 165px;
    width: auto;
    transition-duration: 0.3s;
    transition-property: transform;
}
.block-image.slider .item-image img:hover {
    transform: scale(1.1);
}

/**
   * 5.13. Block Instagram
   */
.block-instagram .right .row:first-child {
    margin-bottom: 30px;
}
.block-instagram a.instagram {
    display: inline-block;
    position: relative;
    vertical-align: top;
    overflow: hidden;
}
.block-instagram a.instagram:after,
.block-instagram a.instagram:before {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.block-instagram a.instagram:before {
    content: "";
    top: 0;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.45);
    z-index: 1;
}
.block-instagram a.instagram:after {
    content: "\f16d";
    color: #fff;
    font-family: FontAwesome;
    font-size: 30px;
    font-weight: 400;
    top: calc(50% - 13px);
    text-align: center;
    line-height: 100%;
    z-index: 2;
}
.block-instagram a.instagram:hover:after,
.block-instagram a.instagram:hover:before {
    opacity: 1;
    visibility: visible;
}
.block-instagram a.instagram img {
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
}
.block-instagram a.instagram:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

/**
   * 5.14. Block Countdown
   */
.block-countdown .countdown-wrap {
    text-align: center;
}
.block-countdown .countdown-wrap .countdown-title {
    color: #ffffff;
    font-size: 50px;
    font-weight: 300;
    margin: 75px 0 15px 0;
}
.block-countdown .countdown-wrap .product-title {
    margin: 0 0 5px;
    font-size: 20px;
}
.block-countdown .countdown-wrap .product-title a {
    color: #fff;
}
.block-countdown .countdown-wrap .product-title a:hover {
    color: #000;
}
.block-countdown .countdown-wrap .product-price {
    color: #fff;
    font-size: 20px;
}
.block-countdown .countdown-wrap .countdown .countdown-deal {
    margin: 30px 0;
}
.block-countdown .countdown-wrap .countdown .countdown-content > span {
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}
.block-countdown
    .countdown-wrap
    .countdown
    .countdown-content
    > span:last-child {
    margin-right: 0;
}
.block-countdown
    .countdown-wrap
    .countdown
    .countdown-content
    .countdown-amount {
    display: inline-block;
    width: 62px;
    height: 62px;
    color: #000;
    font-size: 26px;
    text-align: center;
    line-height: 62px;
    font-weight: 500;
    background: #fff;
    border-radius: 50%;
}
.block-countdown .countdown-wrap .countdown .countdown-content .countdown-text {
    display: block;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    margin-top: 10px;
    letter-spacing: 1.5px;
}
.block-countdown .countdown-wrap .countdown-button a {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 45px;
    letter-spacing: 2px;
    border-radius: 25px 25px 25px 25px;
    padding: 15px 35px 15px 35px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #000000;
}
.block-countdown .countdown-wrap .countdown-button a:hover {
    color: #000000;
    background-color: #ffffff;
}
.block-countdown .countdown-img {
    position: relative;
    padding: 0 15px;
}
.block-countdown .countdown-img .sub-img {
    position: absolute;
    top: -40px;
    right: 75px;
}
.block-countdown .countdown-img .sub-img .image-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

/**
   * 5.15. Block Map
   */
.block-map iframe {
    height: 220px;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
        hue-rotate(0deg);
    border: none;
    max-width: 100%;
    width: 100%;
}

/**
   * 5.16. Block Parallax
   */
.block-parallax {
    background-image: url(../src/assets/frontimg/banner/parallax.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 60px;
    padding: 180px 15px 180px 15px;
}
.block-parallax .content {
    text-align: center;
}
.block-parallax .content .subtitle {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    margin-bottom: 15px;
}
.block-parallax .content .title {
    color: #ffffff;
    font-size: 120px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 20px;
}
.block-parallax .content .description {
    color: #ffffff;
    font-size: 30px;
    letter-spacing: 3px;
    line-height: 1;
    margin-bottom: 38px;
}

/**
   * 5.17. Block Product Filter
   */
.block-product-filter .filter-item .filter-title {
    color: #444;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #e7e7e7;
    font-weight: 700;
}
.block-product-filter .filter-item .filter-content ul {
    overflow-y: scroll;
    max-height: 126px;
    padding-left: 0;
    list-style: none;
}
.block-product-filter .filter-item .filter-content ul::-webkit-scrollbar {
    width: 10px;
    background-color: #eaeaea;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}
.block-product-filter .filter-item .filter-content ul::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #000;
}
.block-product-filter .filter-item .filter-content ul::-webkit-scrollbar-track {
    background-color: #eaeaea;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}
.block-product-filter .filter-item .check {
    cursor: pointer;
}
.block-product-filter .filter-item .custom-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.block-product-filter .filter-item .custom-checkbox input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
}
.block-product-filter .filter-item .custom-checkbox .checkmark {
    margin-right: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
    background-color: #fff;
}
.block-product-filter .filter-item .custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid #666;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.block-product-filter
    .filter-item
    .custom-checkbox
    input:checked
    ~ .checkmark:after {
    display: block;
}
.block-product-filter .filter-item .check a {
    font-weight: normal;
}
.block-product-filter .layout-slider {
    width: 100%;
    padding: 0 5px;
    margin-bottom: 20px;
}
.block-product-filter .jslider .jslider-label {
    font-size: 13px;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-bg
    i.f {
    background: #ddd;
    height: 3px;
    width: 100% !important;
    left: 0;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-bg
    i.v {
    background: #000;
    height: 3px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-bg
    i.l,
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-bg
    i.r {
    display: none;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-pointer {
    background: #000;
    width: 13px;
    height: 13px;
    top: -5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-value {
    float: left;
    margin-left: -5px !important;
    margin-top: 36px;
    font-size: 14px;
    color: black;
    background: transparent;
}
.block-product-filter
    .layout-slider
    span.jslider.jslider_plastic
    .jslider-value.jslider-value-to {
    float: right;
    margin-right: -10px;
}
.block-product-filter .jslider .jslider-label {
    left: -4px;
}
.block-product-filter .jslider .jslider-label-to {
    left: auto;
    right: -3px;
}
.block-product-filter ul.filter-items {
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    float: left;
    margin-bottom: 0;
}
.block-product-filter ul.filter-items li {
    color: #868686;
    margin-right: 10px;
    text-align: center;
    float: left;
    font-size: 14px;
    cursor: pointer;
}
.block-product-filter ul.filter-items li:last-child {
    margin-right: 0;
}
.block-product-filter ul.filter-items li span {
    border: 1px solid #ddd;
    display: block;
}
.block-product-filter ul.filter-items li:hover {
    color: #000;
}
.block-product-filter ul.filter-items li:hover span {
    border: 1px solid #000;
}
.block-product-filter ul.filter-items.text li {
    width: 48px;
    height: 48px;
    line-height: 46px;
}
.block-product-filter ul.filter-items.image {
    margin: 0 -5px;
}
.block-product-filter ul.filter-items.image li {
    width: 33.33%;
    padding: 0 5px;
    margin: 0 0 10px 0;
}

/**
   * 5.18. Blog Post Search
   */
.block-post-search .search-from {
    position: relative;
    overflow: hidden;
}
.block-post-search .search-from input[type="text"] {
    line-height: 45px;
    height: 45px;
    border: 1px solid #e6e6e6;
    padding: 0 70px 0 15px;
    width: 100%;
}
.block-post-search .search-from .btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    height: 45px;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: transparent;
    padding: 0 20px;
    border-radius: 0;
}
.block-post-search .search-from .btn i {
    font-size: 18px;
    line-height: 45px;
    color: #000;
}

/**
   * 5.19. Block Post Categories
   */
.block-post-cats ul {
    padding-left: 0;
    list-style-type: none;
}
.block-post-cats ul li {
    display: block;
    margin-bottom: 10px;
}
.block-post-cats ul li a {
    color: #868686;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.block-post-cats ul li.current a,
.block-post-cats ul li a:hover {
    color: #000;
}
.block-post-cats ul li .count {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 400;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #f1f1f1;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    display: block;
}

/**
   * 5.20. Block Post Archives
   */
.block-post-archives ul {
    padding: 0;
    list-style: none;
}
.block-post-archives ul li:before {
    content: "\35";
    display: inline-block;
    font-family: eleganticons;
    font-size: 15px;
    margin-right: 3px;
    position: relative;
    top: 1px;
    color: #000;
    line-height: 1;
}
.block-post-archives ul li {
    margin-bottom: 5px;
}
.block-post-archives ul li a {
    color: #868686;
}
.block-post-archives ul li a:hover {
    color: #000;
}

/**
   * 5.21. Block Post Tags
   */
.block-post-tags ul {
    padding: 0;
    list-style: none;
}
.block-post-tags ul li {
    display: inline-block;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    background: transparent;
    padding: 6px 15px;
    margin: 0 6px 4px 0;
    text-transform: capitalize;
    position: relative;
}
.block-post-tags ul li:hover {
    background: #000;
    border-color: #000;
}
.block-post-tags ul li a {
    color: #868686;
}
.block-post-tags ul li:hover a {
    color: #fff;
}

/**
   * 5.22. Block Contact Map
   */
.block-contact-map iframe {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
    height: 440px;
}

/**
   * 5.23. Block Contact Info
   */
.block-contact-info {
    text-align: center;
}
.block-contact-info .info-icon {
    font-size: 65px;
    display: inline-block;
    margin-bottom: 10px;
}
.block-contact-info .info-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.block-contact-info .info-title {
    margin-bottom: 45px;
}
.block-contact-info .info-title h2 {
    font-size: 35px;
    padding: 0;
    margin: 0;
    line-height: 1;
}
.block-contact-info .info-item .item-tilte h2 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 15px;
}
.block-contact-info .info-item .item-content p {
    margin-bottom: 3px;
}
.block-contact-info .info-item .item-content.small-width {
    max-width: 290px;
    margin: 0 auto;
}

/**
   * 5.24. Block Contact Form
   */
.block-contact-form .block-title {
    text-align: center;
    margin-bottom: 40px;
}
.block-contact-form .block-title h2 {
    font-size: 35px;
}
.block-contact-form .block-title .sub-title {
    font-size: 16px;
}
.block-contact-form .contact-us-form label {
    color: #000;
    margin-bottom: 10px;
    font-size: 16px;
    position: relative;
}
.block-contact-form .contact-us-form label.required:before {
    content: "*";
    color: #000;
    position: absolute;
    top: -1px;
    right: -8px;
}
.block-contact-form .contact-us-form input[type="email"],
.block-contact-form .contact-us-form input[type="text"] {
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    margin-bottom: 20px;
    width: 100%;
    padding: 10px 20px;
    color: #000;
    font-weight: 400;
    border: 1px solid #000;
    background: #fff;
}
.block-contact-form .contact-us-form .form-button {
    text-align: center;
    margin-top: 30px;
}
.block-contact-form .contact-us-form .button {
    height: 42px;
    line-height: 42px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 3px;
    font-weight: 500;
    padding: 0 50px;
    color: #fff;
    background: #000;
    border: 1px solid #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}
.block-contact-form .contact-us-form .button:hover {
    opacity: 0.8;
}

/*===================================================================
  6. Product Functions
  ====================================================================*/
/**
   * 6.1. Product - Wishlist
   */
.wishlist-empty {
    text-align: center;
}
.wishlist-items {
    margin: 0;
}
.wishlist-items tr {
    border-bottom: 1px solid #e5e5e5;
}
.wishlist-items tr td {
    border: 0;
    background: transparent;
    padding: 15px 10px;
    vertical-align: middle;
}
.wishlist-items tr td:first-child {
    padding-left: 20px;
}
.wishlist-items tr td:last-child {
    padding-right: 20px;
}
.wishlist-items .wishlist-item .wishlist-item-remove {
    vertical-align: middle;
    width: 16px;
}
.wishlist-items .wishlist-item .wishlist-item-remove span {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
    display: inline-block;
    content: "\e9ea";
    font-size: 16px;
    font-family: "feather";
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:hover:before {
    color: #cc6055;
}
.wishlist-items .wishlist-item .wishlist-item-remove span.removing:before {
    animation: remove-spinner 1s linear infinite;
    -webkit-animation: remove-spinner 1s linear infinite;
}
.wishlist-items .wishlist-item .wishlist-item-name,
.wishlist-items .wishlist-item .wishlist-item-name a {
    font-weight: 400;
    font-size: 16px;
}
.wishlist-items .wishlist-item-remove span:before {
    content: "\ea02" !important;
}
.wishlist-items .wishlist-item .wishlist-item-image {
    width: 100px;
}
.wishlist-items .wishlist-item .wishlist-item-image img {
    width: 95px !important;
    height: auto;
    border: 1px solid #e5e5e5;
    padding: 0;
    margin: 0;
    box-shadow: none;
}
.wishlist-items .wishlist-item .wishlist-item-price ins {
    text-decoration: none;
}
.wishlist-items .wishlist-item .wishlist-item-price ins span {
    color: #ff4545;
}
.wishlist-items .wishlist-item .wishlist-item-time {
    font-size: 14px;
}
.wishlist-items .wishlist-item .wishlist-item-actions {
    text-align: right;
}
.wishlist-items .wishlist-item .wishlist-item-add p {
    border: none;
    padding: 0;
    margin: 0;
}
.wishlist-items .wishlist-item .wishlist-item-add a {
    color: #fff;
    display: inline-block;
    line-height: 28px;
    border: 1px solid #000;
    background: #000;
    padding: 0 12px;
    font-size: 14px;
    margin-top: 15px;
}
.wishlist-items .wishlist-item .wishlist-item-add a:hover {
    opacity: 0.8;
}
.wishlist-items .wishlist-item .btn-add-to-cart a.loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid #a9a8a8;
    border-top-color: #000;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 3px;
    margin-right: 6px;
}
.wishlist-items .wishlist-item .btn-add-to-cart a.added {
    display: none;
}
.wishlist-items .wishlist-item .btn-add-to-cart a.added-to-cart:before {
    content: "\4e";
    font-family: ElegantIcons;
    margin-right: 5px;
    vertical-align: bottom;
}
.wishlist-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999989;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    font-size: 14px;
    visibility: hidden;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    box-sizing: border-box;
}
.wishlist-popup.show {
    opacity: 1;
    visibility: visible;
}
.wishlist-popup * {
    box-sizing: border-box;
}
.wishlist-popup .wishlist-popup-inner {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}
.wishlist-popup .wishlist-popup-inner .wishlist-popup-content {
    max-width: 670px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    background: #fff;
    width: 90%;
    max-width: 480px;
    height: auto;
    max-height: 90%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    padding: 0;
    display: flex;
    flex-direction: column;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 4px;
    overflow: hidden;
}
.wishlist-popup.show .wishlist-popup-inner .wishlist-popup-content {
    top: 50% !important;
}
.wishlist-popup .wishlist-popup-inner .wishlist-popup-content > div {
    align-self: stretch;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top {
    background: #fff;
    color: #000;
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    flex: 0 0 auto;
    margin: 0;
    position: relative;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top:before {
    font-size: 22px;
    color: #000;
    line-height: 35px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    content: "\e977";
    font-family: feather;
    margin: 0;
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-count-wrapper:before {
    content: "(";
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-count-wrapper:after {
    content: ")";
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close {
    cursor: pointer;
    position: absolute;
    text-align: center;
    z-index: 9999;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;
    top: 16px;
    right: 16px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border: 1px solid hsla(0, 0%, 53%, 0.35);
    line-height: 48px;
    text-transform: none;
    color: #999;
    font-weight: 400;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:after,
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: calc(50% - 5px);
    margin-top: -1px;
    background-color: hsla(0, 0%, 53%, 0.35);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:before {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:after {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
    float: right;
    line-height: 48px;
    text-align: center;
    font-size: 20px;
    font-family: "feather";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:hover:after,
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top
    .wishlist-popup-close:hover:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-mid {
    display: block;
    position: relative;
    min-height: 80px;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}
.wishlist-popup .wishlist-popup-content-mid:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 7;
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0;
    visibility: hidden;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot {
    background: #fff;
    color: #000;
    padding: 0;
    border-top: 1px solid #e5e5e5;
    height: 60px;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 0 0 auto;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-popup-content-bot-inner {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-popup-content-bot-inner
    a,
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-popup-content-bot-inner
    span {
    color: #fff;
    text-decoration: underline;
    outline: none;
    cursor: pointer;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-page {
    font-size: 13px;
    line-height: 35px;
    background: #000;
    font-weight: 500;
    padding: 0 25px;
    text-transform: uppercase;
    text-decoration: unset !important;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-page:hover {
    background: transparent;
    color: #000;
    border: 2px solid #000;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-continue {
    display: inline-block;
    line-height: 31px;
    padding: 0 25px;
    border: 2px solid #000;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000 !important;
    text-decoration: unset !important;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-continue:after {
    font-family: eleganticons;
    content: "\24";
    display: inline-block;
    font-size: 18px;
    position: relative;
    top: 3px;
    line-height: 1;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-continue:hover {
    background: #000;
    color: #fff !important;
    border-color: #000;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-notice {
    background-color: #000000;
    height: 60px;
    line-height: 60px;
    top: 60px;
    display: block;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-notice.wishlist-notice-show {
    top: 0;
}
.wishlist-popup .wishlist-empty {
    margin-top: 25px;
}

/**
   * 6.2. Product - Compare
   */
.compare-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999979;
    box-sizing: border-box;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
}
.compare-popup.active {
    opacity: 1;
    visibility: visible;
}
.compare-popup .compare-popup-inner {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}
.compare-popup .compare-popup-inner .compare-table {
    padding: 15px;
    margin: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgb(41, 42, 48);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999997;
    pointer-events: auto;
}
.compare-popup .compare-popup-inner .compare-table * {
    box-sizing: border-box;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
    background-color: #fff;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    position: relative;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0;
    visibility: hidden;
    z-index: 7;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-close {
    z-index: 6;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-close
    .compare-table-close-icon {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 52px;
    min-height: 52px;
    background-color: #eee;
    /* background-image: url(../img/remove-dark.svg); */
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-settings {
    /* background-image: url(../img/checkbox-dark.svg); */
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 20px;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items {
    z-index: 5;
    height: 100%;
    position: relative;
    overflow: auto;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table {
    border-spacing: 0;
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 6;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead {
    z-index: 8;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead
    tr {
    border: none;
    margin: 0;
    padding: 0;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead
    tr
    th {
    position: relative;
    border-collapse: collapse;
    z-index: 8 !important;
    text-transform: uppercase;
    padding: 15px 15px 15px 20px;
    margin: 0;
    width: auto;
    min-width: 200px;
    max-width: 300px;
    vertical-align: middle;
    color: #43454b;
    font-weight: 700;
    text-align: left;
    background-color: #f7f7f7;
    border: none;
    border-image-width: 0;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead
    tr
    th:first-child {
    padding-left: 20px;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody {
    z-index: 7;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead
    tr
    th
    a {
    text-decoration: none;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    thead
    tr
    th
    .remove {
    text-transform: none;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr
    td {
    position: relative;
    border-collapse: collapse;
    background-color: #fff;
    padding: 15px 15px 15px 0;
    vertical-align: top;
    text-align: left;
    width: auto;
    min-width: 200px;
    max-width: 300px;
    color: #43454b;
    font-weight: 400;
    border: none;
    z-index: 6;
    border-image-width: 0;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr
    td:first-child {
    z-index: 7;
    padding-left: 15px;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr:hover
    td {
    background-color: #f8f8f8;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr.tr-image
    a {
    text-decoration: none;
    outline: none;
    display: block;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr.tr-image
    img {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    height: auto;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    box-shadow: none;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr:nth-child(2n)
    td {
    background-color: #fbfbfb;
}
.compare-popup
    .compare-popup-inner
    .compare-table
    .compare-table-inner
    .compare-table-items
    table
    tbody
    tr.tr-rating
    td
    .star-rating {
    float: left;
}

/**
   * 6.3. Product - Quickview
   */
.quickview-popup {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
}
.quickview-popup.active {
    opacity: 1;
    visibility: visible;
}
.quickview-popup .quickview-container {
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.quickview-popup .quickview-container .quickview-close {
    text-align: center;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    color: #000;
    z-index: 10000;
    width: 36px;
    height: 36px;
    border: 9px solid transparent;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
.quickview-popup .quickview-container .quickview-close:after,
.quickview-popup .quickview-container .quickview-close:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.quickview-popup .quickview-container .quickview-close:before {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.quickview-popup .quickview-container .quickview-close:after {
    transform-origin: 50% 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.quickview-popup .quickview-container .quickview-close:hover {
    background: #e5e5e5;
}
.quickview-popup .quickview-container .quickview-close:hover:after,
.quickview-popup .quickview-container .quickview-close:hover:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.quickview-notices-wrapper {
    max-width: 1410px;
    margin: auto;
    width: 100%;
}
.quickview-popup .quickview-container .product-detail {
    overflow: hidden;
    display: block;
    background: #fff;
    color: #868686;
    max-width: 1000px;
}
.quickview-popup .quickview-container .product-detail > .row {
    margin: 0;
    align-items: center;
}
.quickview-popup .quickview-container .img-quickview {
    width: 55%;
}
.quickview-popup .quickview-container .img-quickview .slick-arrow {
    top: calc(50% - 10px);
    font-size: 20px;
}
.quickview-popup
    .quickview-container
    .img-quickview
    .slick-arrow.fa-angle-left {
    left: 15px;
}
.quickview-popup
    .quickview-container
    .img-quickview
    .slick-arrow.fa-angle-right {
    right: 15px;
}
.quickview-popup .quickview-container .slick-list,
.quickview-popup .quickview-container .slick-track {
    display: flex;
}
.quickview-popup .quickview-container .product-images-slider .img-thumbnail {
    border: 0;
    display: inline-block;
    float: unset;
}
.quickview-popup .quickview-container .quickview-single-info {
    width: 45%;
    padding: 0 45px;
}
.quickview-popup
    .quickview-container
    .quickview-single-info
    .product-content-detail {
    max-height: 485px;
    padding-right: 8px;
    overflow-x: hidden;
}
.quickview-container .product-title,
.single-product .product-title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    word-break: break-word;
    display: block;
}
.quickview-container .entry-summary .price-single,
.single-product .entry-summary .price-single {
    width: 100%;
    line-height: 100%;
    display: flex;
    margin-bottom: 20px;
}
.quickview-container .entry-summary .price,
.single-product .entry-summary .price {
    color: #000;
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
}
.quickview-container .entry-summary .price del,
.single-product .entry-summary .price del {
    font-size: 20px;
}
.quickview-popup .quickview-container .product-rating {
    display: flex;
}
.quickview-container .quickview-single-info .star-rating,
.single-product .quickview-single-info .star-rating {
    display: inline-block;
    margin-right: 10px;
    width: 85px;
    font-size: 13px;
    margin-bottom: 0;
}
.quickview-container .quickview-single-info .star-rating:before,
.quickview-container .quickview-single-info .star-rating span:before,
.single-product .quickview-single-info .star-rating:before,
.single-product .quickview-single-info .star-rating span:before {
    letter-spacing: 4.8px;
}
.quickview-container .quickview-single-info .review-link,
.single-product .quickview-single-info .review-link {
    position: relative;
    top: -5px;
    color: #868686;
    font-size: 14px;
}
.quickview-popup .quickview-container .quickview-single-info .description {
    margin-bottom: 15px;
}
.quickview-popup .quickview-single-info .description,
.single-product .quickview-single-info .description {
    margin-bottom: 13px;
    padding-top: 30px;
    margin-top: 30px;
    padding-bottom: 15px;
    border-top: 1px solid #efefef;
}
.quickview-container .quickview-single-info .description > p:last-child,
.single-product .quickview-single-info .description > p:last-child {
    margin-bottom: 0;
}
.quickview-container
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart,
.single-product
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart {
    margin-top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.quickview-container .quickview-single-info .cart,
.single-product .quickview-single-info .cart {
    padding: 0;
}
.quickview-container
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity-button,
.single-product
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity-button {
    display: flex;
    flex: 1;
    margin: 5px 0;
}
.quickview-container
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity,
.quickview-container
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .single-add-to-cart-button,
.quickview-container
    .product-type-variable
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity,
.quickview-container
    .product-type-variable
    .quickview-single-info
    .entry-summary
    > .cart
    .single-add-to-cart-button,
.single-product
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity,
.single-product
    .product-type-simple
    .quickview-single-info
    .entry-summary
    > .cart
    .single-add-to-cart-button,
.single-product
    .product-type-variable
    .quickview-single-info
    .entry-summary
    > .cart
    .quantity,
.single-product
    .product-type-variable
    .quickview-single-info
    .entry-summary
    > .cart
    .single-add-to-cart-button {
    margin-bottom: 0;
}
.quickview-popup .quickview-container .quickview-single-info .quantity {
    margin-right: 10px;
    display: block !important;
    visibility: visible !important;
}
.quickview-container .quickview-single-info .quantity,
.single-product .quickview-single-info .quantity {
    margin: 0;
    border: 1px solid #b5b5b5 !important;
    height: 50px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
}
.quickview-container .quickview-single-info .quantity button,
.single-product .quickview-single-info .quantity button {
    outline-color: transparent;
    color: #868686;
    font-size: 0;
}
.quickview-container .quickview-single-info .quantity button:before,
.single-product .quickview-single-info .quantity button:before {
    font-family: eleganticons;
    display: inline-block;
    font-size: 18px;
    font-weight: 900;
}
.quickview-container .quickview-single-info .quantity button.plus,
.single-product .quickview-single-info .quantity button.plus {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    line-height: 24px;
    border: none;
    width: 38px;
    height: 46px;
}
.quickview-container .quickview-single-info .quantity button.plus:before,
.single-product .quickview-single-info .quantity button.plus:before {
    content: "\4c";
    position: relative;
    top: 4px;
}
.quickview-container .quickview-single-info .quantity button.minus,
.single-product .quickview-single-info .quantity button.minus {
    position: absolute;
    left: 0;
    bottom: 0;
    border: none;
    width: 38px;
    height: 46px;
    background: transparent;
}
.quickview-container .quickview-single-info .quantity button.minus:before,
.single-product .quickview-single-info .quantity button.minus:before {
    content: "\4b";
    position: relative;
    top: 4px;
}
.quickview-container .quickview-single-info .quantity button:focus,
.quickview-container .quickview-single-info .quantity button:hover,
.single-product .quickview-single-info .quantity button:focus,
.single-product .quickview-single-info .quantity button:hover {
    border: none;
    color: #000;
}
.quickview-container .quickview-single-info .quantity input,
.single-product .quickview-single-info .quantity input {
    width: 125px;
    border: 0;
    height: 46px;
    text-align: center;
    padding: 0 38px;
    font-size: 18px;
    font-weight: 500;
}
.quickview-container .quickview-single-info .single-add-to-cart-button.button,
.single-product .quickview-single-info .single-add-to-cart-button.button {
    -webkit-transition: none;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 50px;
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    margin-right: 20px;
    padding: 0 15px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    flex: 1;
    margin: 0;
    white-space: nowrap;
}
.quickview-container
    .quickview-single-info
    .single-add-to-cart-button.button:hover:not(.disabled),
.single-product
    .quickview-single-info
    .single-add-to-cart-button.button:hover:not(.disabled) {
    background: #000;
    color: #fff;
}
.quickview-container .quickview-single-info .button.quick-buy,
.single-product .quickview-single-info .button.quick-buy {
    -webkit-transition: none;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 50px;
    position: relative;
    background: transparent;
    color: #000;
    border: 1px solid #000;
    text-align: center;
    margin-right: 20px;
    padding: 0 15px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 5px 0;
    white-space: nowrap;
}
.quickview-container
    .quickview-single-info
    .button.quick-buy:hover:not(.disabled),
.single-product .quickview-single-info .button.quick-buy:hover:not(.disabled) {
    background: #000;
    color: #fff;
}
.quickview-popup .quickview-container .slick-dots {
    position: absolute;
    bottom: 35px;
}
.quickview-popup .quickview-container .slick-dots li,
.quickview-popup .quickview-container .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.quickview-popup .quickview-container .slick-dots li button:before,
.quickview-popup .quickview-container .slick-dots li button:after {
    content: none;
}
.quickview-popup .quickview-container .slick-list,
.quickview-popup .quickview-container .slick-track {
    display: flex;
}
.quickview-popup .quickview-container .img-quickview .slick-arrow {
    top: calc(50% - 10px);
    font-size: 20px;
}
.quickview-popup
    .quickview-container
    .img-quickview
    .slick-arrow.fa-angle-left {
    left: 15px;
}
.quickview-popup
    .quickview-container
    .img-quickview
    .slick-arrow.fa-angle-right {
    right: 15px;
}

/*===================================================================
  7. Shop
  ====================================================================*/
/**
   * 7.1. Shop - Top Bar
   */
.products-topbar {
    margin-bottom: 30px;
}
.products-topbar .products-topbar-left {
    float: left;
}
.products-topbar .products-topbar-right {
    float: right;
}
.products-topbar .products-count {
    margin-top: 5px;
    font-size: 16px;
    display: none;
}
.products-topbar .products-sort {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 33px;
    text-transform: capitalize;
    cursor: pointer;
    float: right;
}
.products-topbar .products-sort .sort-toggle {
    position: relative;
    border: 1px solid #000;
    display: inline-block;
    line-height: 34px;
    padding: 0 20px;
}
.products-topbar .products-sort .sort-toggle:after {
    content: "\43";
    font-family: eleganticons;
    font-size: 13px;
    padding-left: 5px;
    display: inline-block;
    width: auto;
    height: auto;
    margin-left: 0;
    vertical-align: 0;
    border: 0;
    line-height: 1;
    position: relative;
    top: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform-origin: 65% 50%;
    color: #000;
}
.products-topbar .products-sort .sort-list {
    min-width: 210px;
    z-index: 9999;
    right: 0;
    left: auto !important;
    padding: 10px 15px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    border-radius: 0;
}
.products-topbar .products-sort .sort-list li {
    line-height: 28px;
    text-transform: capitalize;
    font-weight: 400;
}
.products-topbar .products-sort .sort-list li a {
    color: #868686;
}
.products-topbar .products-sort .sort-list li a:hover {
    color: #000;
}
.products-topbar .layout-toggle {
    float: right;
    list-style: none;
    padding: 9px 10px;
    margin-right: 10px;
    border: 1px solid #e1e1e1;
    background: #fff;
    line-height: 1;
}
.products-topbar .layout-toggle li {
    float: left;
    padding-right: 10px;
    position: relative;
}
.products-topbar .layout-toggle li:last-child {
    padding-right: 0;
}
.products-topbar .layout-toggle li a {
    display: inline-block;
    text-align: center;
    height: 17px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 17px;
    padding: 0;
    border: none;
}
.products-topbar .layout-toggle li a.active,
.products-topbar .layout-toggle li a:hover {
    background: #fff;
    border-color: #000;
}
.products-topbar .layout-toggle li a .icon-column {
    vertical-align: middle;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    z-index: 0;
    display: inline-block;
}
.products-topbar .layout-toggle li a .icon-column .layer {
    position: absolute;
    left: 0;
    height: 5px;
}
.products-topbar .layout-toggle li a .icon-column .layer.first {
    top: 0;
}
.products-topbar .layout-toggle li a .icon-column .layer.middle {
    top: 6px;
}
.products-topbar .layout-toggle li a .icon-column .layer.last {
    top: 12px;
}
.products-topbar .layout-toggle li a .icon-column .layer > span {
    position: absolute;
    display: block;
    width: 5px;
    height: 100%;
    background: #cecece;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
.products-topbar .layout-toggle li a.active .icon-column .layer > span,
.products-topbar .layout-toggle li a:hover .icon-column .layer > span {
    background: #000;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:first-child {
    left: 0;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:nth-child(2) {
    left: 6px;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:last-child {
    left: 12px;
}
.products-topbar .layout-toggle li a.layout-list {
    width: 25px;
}
.products-topbar
    .layout-toggle
    li
    a.layout-list
    .icon-column
    .layer
    > span:first-child {
    left: 0;
}
.products-topbar
    .layout-toggle
    li
    a.layout-list
    .icon-column
    .layer
    > span:last-child {
    left: 6px;
    width: 17px;
    height: 1px;
    top: 2px;
}

/**
   * 7.2. Shop - Products
   */
.products-list.grid .product-wapper {
    position: relative;
    padding: 0;
    margin: 0 0 40px;
    text-align: center;
}
.products-list.grid .product-wapper {
    position: relative;
    padding: 0;
    margin: 0 0 40px;
    text-align: center;
}
.products-list.grid .product-wapper .products-thumb {
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
}
.products-list.grid .product-wapper .products-thumb:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
    animation: h 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    opacity: 0;
    visibility: hidden;
}
.products-list.grid .product-wapper .products-thumb .product-lable > div {
    overflow: hidden;
    display: inline-block;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-lable
    > div:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
    animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    opacity: 0;
    visibility: hidden;
}
.products-list.grid .product-wapper .products-thumb .product-stock {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    background: #fff;
    color: #000;
    padding: 0 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
}
.products-list.grid .product-wapper .products-thumb .product-thumb-hover {
    position: relative;
    display: block;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-thumb-hover
    .post-image {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    height: auto;
}
.products-list.grid
    .product-wapper:hover
    .products-thumb
    .product-thumb-hover
    .post-image {
    opacity: 0;
    visibility: hidden;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-thumb-hover
    .hover-image {
    opacity: 0;
    visibility: hidden;
    max-width: 100%;
    height: auto;
}
.products-list.grid
    .product-wapper:hover
    .products-thumb
    .product-thumb-hover
    .hover-image {
    opacity: 1;
    visibility: visible;
}
.products-list.grid .product-wapper .products-thumb .product-button {
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    z-index: 9;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    > :nth-child(odd) {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
}
.products-list.grid .product-wapper .products-thumb .product-button > * {
    margin: 0 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    position: relative;
}
.products-list.grid .product-wapper:hover .products-thumb .product-button > * {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
}
.products-list.grid .product-wapper .products-thumb .product-button > :before {
    content: attr(data-title);
    position: absolute;
    padding: 0 10px;
    background: #000;
    color: #fff;
    top: -30px;
    line-height: 23px;
    white-space: nowrap;
    left: 50%;
    font-size: 12px;
    z-index: 10;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    pointer-events: none;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    > :hover:after,
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    > :hover:before {
    opacity: 1;
    visibility: visible;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn {
    font-size: 0;
    background: #fff;
    text-align: center;
    position: relative;
    white-space: nowrap;
    margin-top: 0;
    border: 0;
    color: #fff;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn:hover {
    background: #000;
    border-color: #000;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn:before {
    content: "\e900";
    font-family: icomoon;
    font-size: 16px;
    line-height: 40px;
    color: #000;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn:hover:before {
    color: #fff;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn.adding:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-top-color: #000;
    width: 16px;
    height: 16px;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 7px;
    left: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn.adding:hover:before {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-top-color: #fff;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn.added:before {
    line-height: 40px;
    margin: 0;
    content: "\e90";
    font-family: icomoon;
    color: #000;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    position: relative;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-btn.added:hover:before {
    color: #fff;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-add-to-cart
    .product-btn:before {
    content: "\e901";
    top: 0;
    margin: 0;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-add-to-cart
    .product-btn.loading:before {
    content: "";
    margin-top: 11px;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-add-to-cart
    .added-to-cart:before {
    content: "\4e";
    font-family: ElegantIcons;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-compare
    .product-btn:before {
    content: "\e90f";
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-compare
    .product-btn.adding:before {
    content: "";
}
.products-list.grid .product-wapper .products-thumb .product-button > :after {
    top: -5px;
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview {
    text-align: center;
    background: #fff;
    font-size: 0;
    display: block;
    white-space: nowrap;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview:hover
    > a {
    background: #000;
    border-color: #000;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview
    > a {
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    display: inline-block;
    position: relative;
    outline: unset;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview
    > a
    > i {
    font-size: 16px;
    color: #000;
    line-height: 40px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview
    .loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-top-color: #000;
    width: 18px;
    height: 18px;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview:hover
    .loading:before,
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview:hover
    > a
    > i {
    color: #fff;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview
    .loading:hover:before {
    border-color: hsla(0, 0%, 100%, 0.5);
    border-top-color: #fff;
}
.products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview
    .loading
    i {
    display: none;
}
.products-list.grid .product-wapper .products-content {
    text-align: left;
    margin-top: 14px;
}
.products-list.grid .product-wapper .products-content h3.product-title {
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 6px;
}
.products-list.grid .product-wapper .products-content h3.product-title a {
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.products-list.grid
    .product-wapper
    .products-content
    h3.product-title
    a:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
    animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    opacity: 0;
    visibility: hidden;
}
.products-list.grid .product-wapper .products-content .rating {
    margin-bottom: 4px;
}
.products-list.grid .product-wapper .products-content .price {
    line-height: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.products-list.grid .product-wapper .products-content .price:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
    animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    opacity: 0;
    visibility: hidden;
}
.products-list.grid .product-wapper .products-content .price del {
    line-height: 100%;
}
.products-list.grid .product-wapper .btn-add-to-cart {
    margin-top: 15px;
    text-align: center;
}
.products-list.grid .product-wapper .btn-add-to-cart a {
    display: inline-block;
    border: 1px solid #e8e8e8;
    color: #a9a8a8;
    font-size: 15px;
    line-height: 48px;
    width: 100%;
    padding: 0 15px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    text-align: center;
    position: relative;
    text-transform: lowercase;
}
.products-list.grid .product-wapper .btn-add-to-cart a.loading {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.products-list.grid .product-wapper .btn-add-to-cart a:hover {
    background: #000;
    border-color: #000;
    color: #fff;
}
.products-list.grid .product-wapper .btn-add-to-cart a:before {
    content: "\e901";
    font-family: icomoon;
    font-size: 20px;
    line-height: 30px;
    color: #a9a8a8;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    margin: 0;
    display: inline-block;
    position: relative;
    left: 0;
    top: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin-right: 10px;
}
.products-list.grid .product-wapper .btn-add-to-cart a.loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid #a9a8a8;
    border-top-color: #000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 4px;
}
.products-list.grid .product-wapper .btn-add-to-cart a:hover:before {
    color: #fff;
}
.products-list.grid .product-wapper .btn-add-to-cart a.added {
    display: none;
}
.products-list.grid .product-wapper .btn-add-to-cart a.added-to-cart:before {
    content: "\4e";
    font-family: ElegantIcons;
    text-indent: 0;
    text-transform: none;
}
.products-list.list .product-wapper {
    margin-bottom: 40px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.products-list.list .product-wapper .products-thumb {
    position: relative;
    border: 1px solid #dadada;
    overflow: hidden;
}
.products-list.list .product-wapper .products-thumb .product-thumb-hover {
    position: relative;
    display: block;
    overflow: hidden;
}
.products-list.list .product-wapper .products-thumb .post-image {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.products-list.list .product-wapper .products-thumb:hover .post-image {
    -webkit-transform: translate(100%);
    transform: translate(100%);
}
.products-list.list .product-wapper .products-thumb .hover-image {
    top: 0;
    position: absolute;
    overflow: hidden;
    left: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.products-list.list .product-wapper .products-thumb:hover .hover-image {
    -webkit-transform: translate(0);
    transform: translate(0);
}
.products-list.list .product-wapper .products-thumb .product-quickview {
    position: absolute;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
    width: 42px;
    height: 42px;
    background: #fff;
    text-align: center;
    line-height: 42px;
    z-index: 9999;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.products-list.list .product-wapper:hover .products-thumb .product-quickview {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.products-list.list .product-wapper .products-thumb .product-quickview:hover {
    background: #000;
}
.products-list.list .product-wapper .products-thumb .product-quickview a {
    font-size: 0;
    width: 42px;
    height: 42px;
    display: inline-block;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.list .product-wapper .products-thumb .product-quickview a i {
    font-size: 16px;
    color: #000;
    line-height: 42px;
}
.products-list.list
    .product-wapper
    .products-thumb
    .product-quickview
    .loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-top-color: #000;
    width: 18px;
    height: 18px;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.products-list.list
    .product-wapper
    .products-thumb
    .product-quickview:hover
    .loading:before,
.products-list.list
    .product-wapper
    .products-thumb
    .product-quickview:hover
    > a
    > i {
    color: #fff;
}
.products-list.list
    .product-wapper
    .products-thumb
    .product-quickview
    .loading:hover:before {
    border-color: hsla(0, 0%, 100%, 0.5);
    border-top-color: #fff;
}
.products-list.list
    .product-wapper
    .products-thumb
    .product-quickview
    .loading
    i {
    display: none;
}
.products-list.list .product-wapper .products-content .product-title {
    font-size: 25px;
    margin-top: -2px;
    margin-bottom: 12px;
    font-weight: 400;
}
.products-list.list .product-wapper .products-content .price {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
    display: block;
}
.products-list.list .product-wapper .products-content .rating {
    margin-bottom: 24px;
}
.products-list.list .product-wapper .products-content .product-button {
    display: inline-flex;
    flex-wrap: wrap;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-add-to-cart
    .product-btn {
    display: inline-block;
    text-align: center;
    color: #fff;
    height: 42px;
    position: relative;
    clear: both;
    padding: 0 30px;
    background: #000;
    border: 1px solid #000;
    margin-right: 15px;
    float: left;
    overflow: hidden;
    line-height: 40px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-add-to-cart
    .product-btn:hover {
    background: transparent;
    color: #000;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-add-to-cart
    a.loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid #a9a8a8;
    border-top-color: #000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 3px;
    margin-right: 10px;
}
.products-list.list .product-wapper .products-content .btn-add-to-cart a.added {
    display: none;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-add-to-cart
    a.added-to-cart:before {
    content: "\4e";
    font-family: ElegantIcons;
    margin-right: 5px;
    vertical-align: bottom;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-wishlist
    .product-btn {
    margin-top: 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #000;
    text-align: center;
    font-weight: 500;
    padding: 0;
    position: relative;
    display: inline-block;
    margin-right: 15px;
    float: left;
    font-size: 0;
    background: transparent;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-wishlist
    .product-btn:hover {
    cursor: pointer;
    background: #000;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-wishlist
    .product-btn:before {
    content: "\f08a";
    position: absolute;
    left: 0;
    width: 100%;
    color: #000;
    font-family: FontAwesome;
    font-size: 17px;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-wishlist
    .product-btn:hover:before {
    color: #fff;
}
.products-list.list
    .product-wapper
    .products-content
    .product-button
    .product-btn.adding:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-top-color: #000;
    width: 16px;
    height: 16px;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 7px;
    left: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.products-list.list
    .product-wapper
    .products-content
    .product-button
    .product-btn.adding:hover:before {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-top-color: #fff;
}
.products-list.list
    .product-wapper
    .products-content
    .product-button
    .product-btn.added:before {
    line-height: 40px;
    margin: 0;
    content: "\e90";
    font-family: icomoon;
    color: #000;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    position: relative;
}
.products-list.list
    .product-wapper
    .products-content
    .product-button
    .product-btn.added:hover:before {
    color: #fff;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-compare
    .product-btn {
    text-align: center;
    background: #fff;
    font-size: 0;
    display: block;
    white-space: nowrap;
    color: #000;
    width: 42px;
    height: 42px;
    line-height: 42px;
    display: inline-block;
    border: 0;
    cursor: pointer;
    border: 1px solid #000;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-compare
    .product-btn:before {
    font-size: 16px;
    color: #000;
    line-height: 38px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    content: "\e90f";
    font-family: icomoon;
    margin: 0;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-compare
    .product-btn:hover {
    background: #000;
    border-color: #000;
}
.products-list.list
    .product-wapper
    .products-content
    .btn-compare
    .product-btn:hover:before {
    color: #fff;
}
.products-list.list .product-wapper .products-content .product-description {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
}

/**
   * 7.3. Shop - Details
   */
.shop-details .product-images > .row {
    margin: 0 -7.5px;
}
.shop-details .product-images > .row > div {
    padding: 0 7.5px;
}
.shop-details .product-images .content-thumbnail-scroll {
    position: relative;
}
.shop-details .product-images .content-thumbnail-scroll .slick-list {
    padding: 0 !important;
}
.shop-details .product-images .content-thumbnail-scroll .img-item {
    padding-bottom: 15px;
    vertical-align: top;
    border: 0;
    cursor: pointer;
}
.shop-details
    .product-images
    .content-thumbnail-scroll
    .img-item
    .img-thumbnail-scroll {
    vertical-align: top;
    display: inline-block;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border: 1px solid hsla(0, 0%, 67%, 0.25);
    overflow: hidden;
    margin: 1px;
}
.shop-details
    .product-images
    .content-thumbnail-scroll
    .img-item.slick-current
    .img-thumbnail-scroll {
    border-color: #000;
}
/* .shop-details .product-images .content-thumbnail-scroll .slick-arrow {
    right: calc(50% - 15px);
    left: auto;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: 0;
    -webkit-transform: none;
    transform: none;
    background: #000;
    color: #fff;
    font-family: FontAwesome;
    text-align: center;
  } */
.shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left {
    top: 0;
}
.shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left:before {
    content: "\f106";
}
.shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right {
    bottom: 10px;
    top: auto;
}
.shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right:before {
    content: "\f107";
}
.shop-details
    .product-images
    div.col-md-2:hover
    .content-thumbnail-scroll
    .slick-arrow {
    opacity: 1;
    visibility: visible;
}
.shop-details
    .product-images
    div.col-md-2:hover
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left {
    top: -10px;
}
.shop-details
    .product-images
    div.col-md-2:hover
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right {
    bottom: 0;
}
.shop-details
    .product-images
    .image-thumbnail
    .slick-slide.slick-current
    .img-thumbnail-scroll,
.shop-details
    .product-images
    .image-thumbnail
    .slick-slide:hover
    .img-thumbnail-scroll,
.shop-details .product-images .image-thumbnail .slick-slide:hover a,
.shop-details .product-images .image-thumbnail .slick-slide a.active {
    border-color: #000;
}
.shop-details .product-images .img-item img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
/* .shop-details .product-images .main-image .slick-arrow {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: hsla(0,0%,100%,.5);
    color: #000;
    font-family: FontAwesome;
    text-align: center;
    top: calc(50% - 15px);
    opacity: 1;
    visibility: visible;
    z-index: 9998;
    font-size: 18px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  } */
.shop-details .product-images .main-image .slick-arrow:hover {
    background: #000;
    color: #fff;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-left {
    left: 20px;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-left:before {
    content: "\f104";
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-right {
    right: 20px;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-right:before {
    content: "\f105";
}
.shop-details .product-info {
    padding-left: 30px;
}
.shop-details .product-info .title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 12px;
}
.shop-details .product-info .price {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
    display: block;
}
.shop-details .product-info .price del {
    font-size: 18px;
}
.shop-details .product-info .description {
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #efefef;
    margin-bottom: 20px;
}
.shop-details .variations .label {
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
    margin-right: 15px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    width: 60px;
}
/* .shop-details .variations .label:after {
  position: relative;
  content: ":";
  top: 0;
  right: 0;
  display: inline-block;
  padding-left: 5px;
} */
.shop-details .variations tr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.shop-details .variations .attributes ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.shop-details .variations .attributes ul li {
    display: inline-block;
    margin-right: 10px;
}
.shop-details .variations .attributes ul.text li span {
    width: 28px;
    height: 28px;
    line-height: 26px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 13px;
    text-align: center;
    border: 1px solid #d5d5d5;
    cursor: pointer;
}
.shop-details .variations .attributes ul.text li span:hover {
    border: 1px solid #000;
    color: #000;
}
.shop-details .variations .attributes ul.colors li span {
    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #000;
    cursor: pointer;
    position: relative;
}
.shop-details .variations .attributes ul.colors li span.color-2 {
    background: #5173a6;
}
.shop-details .variations .attributes ul.colors li span.color-3 {
    background: #20b2aa;
}
.shop-details .variations .attributes ul.colors li span:before {
    content: "";
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
    width: 36px;
    height: 36px;
    border: 1px solid #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transform: unset;
    transform: unset;
    margin: 0;
    opacity: 0;
    visibility: hidden;
}
.shop-details .variations .attributes ul.colors li span:hover:before {
    opacity: 1;
    visibility: visible;
}
.shop-details .buttons {
    /* display: flex; */
    vertical-align: top;
    flex-wrap: wrap;
}
.shop-details .buttons .add-to-cart-wrap {
    display: flex;
    margin: 5px 0;
    flex-wrap: wrap;
    flex: 1;
}
.shop-details .buttons .add-to-cart-wrap .quantity {
    margin: 0;
    border: 1px solid #b5b5b5 !important;
    height: 50px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
}
.shop-details .buttons .add-to-cart-wrap .quantity button {
    outline-color: transparent;
    color: #868686;
    font-size: 0;
}
.shop-details .buttons .add-to-cart-wrap .quantity button:before {
    font-family: eleganticons;
    display: inline-block;
    font-size: 18px;
    font-weight: 900;
}
.shop-details .buttons .add-to-cart-wrap .quantity button:hover {
    border: none;
    color: #000;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.plus {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    line-height: 24px;
    border: none;
    width: 38px;
    height: 46px;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.plus:before {
    content: "\4c";
    position: relative;
    top: 4px;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.minus {
    position: absolute;
    left: 0;
    bottom: 0;
    border: none;
    width: 38px;
    height: 46px;
    background: transparent;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.minus:before {
    content: "\4b";
    position: relative;
    top: 4px;
}
.shop-details .buttons .add-to-cart-wrap .quantity input {
    width: 125px;
    border: 0;
    height: 46px;
    text-align: center;
    padding: 0 38px;
    font-size: 18px;
    font-weight: 500;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart {
    -webkit-transition: none;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 50px;
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    margin-right: 20px;
    padding: 0 15px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    flex: 1;
    margin: 0;
    white-space: nowrap;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a {
    color: #fff;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart:hover {
    background: #000;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.loading:before {
    position: relative;
    display: inline-block;
    content: "";
    border: 2px solid #a9a8a8;
    border-top-color: #000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: none;
    -webkit-animation: 2s linear 0s normal none infinite running o;
    animation: 2s linear 0s normal none infinite running o;
    text-indent: 0;
    top: 3px;
    margin-right: 10px;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.added {
    display: none;
}
.shop-details
    .buttons
    .add-to-cart-wrap
    .btn-add-to-cart
    a.added-to-cart:before {
    content: "\4e";
    font-family: ElegantIcons;
    margin-right: 5px;
    vertical-align: bottom;
}
.shop-details .buttons .btn-quick-buy {
    width: 100%;
}
.shop-details .buttons .btn-quick-buy .product-btn {
    -webkit-transition: none;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 50px;
    position: relative;
    background: transparent;
    color: #000;
    border: 1px solid #000;
    text-align: center;
    margin-right: 20px;
    padding: 0 15px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 5px 0;
    white-space: nowrap;
}
.shop-details .buttons .btn-quick-buy .product-btn:hover {
    background: #000;
    color: #fff;
}
.shop-details .buttons .btn-wishlist .product-btn {
    margin-bottom: 15px;
    text-align: center;
    color: #000;
    display: inline-flex;
    cursor: pointer;
    vertical-align: top;
    margin-right: 25px;
    align-items: center;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0;
}
.shop-details .buttons .btn-compare .product-btn {
    margin-top: 20px;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;
}
.shop-details .buttons .btn-wishlist .product-btn:before,
.shop-details .buttons .btn-compare .product-btn:before {
    content: "\e900";
    font-family: icomoon;
    font-size: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #cecece;
    margin-right: 10px;
    color: #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.shop-details .buttons .btn-wishlist .product-btn.added:before {
    content: "\e90";
}
.shop-details .buttons .btn-compare .product-btn:before {
    content: "\e90f";
}
.shop-details .buttons .btn-wishlist .product-btn:hover:before,
.shop-details .buttons .btn-compare .product-btn:hover:before {
    color: #fff;
    background: #000;
    border-color: #000;
}
.shop-details .product-info .product-meta {
    display: inline-block;
    width: 100%;
    text-transform: capitalize;
    border-color: #efefef;
    border-width: 1px 0;
    border-style: solid;
    padding: 20px 0;
    margin: 30px 0;
}
.shop-details .product-info .product-meta > span {
    display: inline-flex;
    color: #868686;
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-right: 25px;
}
.shop-details .product-info .product-meta > span span,
.shop-details .product-info .product-meta > span a {
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    margin-left: 5px;
}
.shop-details .product-info .social-share > a {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #868686;
    font-weight: 500;
    margin-right: 40px;
}
.shop-details .product-info .social-share > a i {
    color: #000;
    font-size: 15px;
    margin-right: 7px;
}
.shop-details .product-tabs {
    margin-top: 60px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
}
.shop-details .product-tabs .product-tabs-wrap {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
}
.shop-details .product-tabs .nav-tabs {
    text-align: center;
    padding: 35px 0 0;
    margin-bottom: 35px;
    border-bottom: none;
    display: block;
}
.shop-details .product-tabs .nav-tabs .nav-item {
    display: inline-block;
    margin: 5px 20px;
}
.shop-details .product-tabs .nav-tabs .nav-item a {
    border: none;
    font-size: 24px;
    color: #868686;
    padding: 0 0 5px 0;
    position: relative;
}
.shop-details .product-tabs .nav-tabs .nav-item a:before {
    position: absolute;
    bottom: 0;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 0;
    height: 2px;
    background: #000;
}
.shop-details .product-tabs .nav-tabs .nav-item a:hover,
.shop-details .product-tabs .nav-tabs .nav-item a.active {
    color: #000;
}
.shop-details .product-tabs .nav-tabs .nav-item a:hover:before,
.shop-details .product-tabs .nav-tabs .nav-item a.active:before {
    width: 100%;
}
.shop-details .product-tabs .tab-content .tab-pane p {
    line-height: 2em;
}
.shop-details .product-tabs .product-attributes th,
.shop-details .product-tabs .product-attributes td {
    border: 1px solid #e5e5e5;
    padding: 16px 30px;
    vertical-align: top;
}
.shop-details .product-tabs .product-attributes th {
    text-transform: capitalize;
    font-weight: 500;
    color: #000;
}
.shop-details .product-tabs .product-reviews .reviews-title {
    font-size: 25px;
    margin-bottom: 30px;
}
.shop-details .product-tabs .product-reviews .comment-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    margin-bottom: 40px;
}
.shop-details .product-tabs .product-reviews .comment-list li {
    padding: 0 15px;
    flex: 1;
    min-width: 50%;
    margin-bottom: 25px;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .content-comment-container {
    border: 1px solid #d9d9d9;
    padding: 30px;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .comment-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .comment-container
    > img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 0;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .comment-container
    .comment-text {
    margin: 0;
    padding-left: 15px;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .comment-container
    .review-author {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .comment-container
    .review-time {
    font-size: 13px;
}
.shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .content-comment-container
    .description {
    color: #000;
    line-height: 22px;
}
.shop-details .product-tabs #review-form .comment-reply-title {
    color: #000;
    font-size: 13px;
    margin-bottom: 30px;
    display: inline-block;
    border: 2px solid #000;
    line-height: 52px;
    padding: 0 35px;
    text-transform: uppercase;
    font-weight: 500;
}
.shop-details .product-tabs #review-form .comment-reply-title:before {
    content: "\e918";
    font-family: wpbingofont;
    display: inline-block;
    font-size: 20px;
    margin-right: 15px;
    font-weight: 400;
}
.shop-details .product-tabs #review-form .required {
    color: red;
}
.shop-details .product-tabs #review-form .comment-form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.shop-details .product-tabs #review-form .comment-form .comment-notes {
    width: 100%;
    padding: 0 10px;
}
.shop-details .product-tabs #review-form .comment-form-rating {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}
.shop-details .product-tabs #review-form .comment-form-rating label {
    font-weight: 500;
    margin-right: 0;
    margin-bottom: 10px;
    color: #000;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars {
    padding-top: 0;
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 7px;
    padding: 5px 0;
    width: 160px;
    position: relative;
    top: 2px;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars span {
    display: inline-block;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars span a {
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    position: relative;
    font-size: 0;
    width: auto;
    margin-right: 3px;
}
.shop-details
    .product-tabs
    #review-form
    .comment-form-rating
    .stars
    span
    a:after {
    content: "\f005";
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: unset;
    text-indent: 0;
    color: #cecece;
}
.shop-details .product-tabs #review-form .comment-form-comment {
    flex: 1;
    padding: 0 10px;
    margin: 0;
    order: 2;
}
.shop-details .product-tabs #review-form .content-info-reviews {
    flex: 1;
    padding: 0 10px;
    order: 1;
    margin: 0;
}
.shop-details
    .product-tabs
    #review-form
    .content-info-reviews
    .comment-form-author,
.shop-details
    .product-tabs
    #review-form
    .content-info-reviews
    .comment-form-email {
    margin-bottom: 25px;
}
.shop-details .product-tabs #review-form .content-info-reviews input,
.shop-details .product-tabs #review-form .comment-form-comment textarea {
    background: #fff;
    padding: 14px 20px;
    border: 1px solid #d6d6d6;
    height: 60px;
    font-weight: 300;
    width: 100%;
}
.shop-details .product-tabs #review-form .comment-form-comment textarea {
    height: 230px;
}
.shop-details .product-tabs #review-form .content-info-reviews .form-submit {
    margin: 0;
}
.shop-details
    .product-tabs
    #review-form
    .content-info-reviews
    .form-submit
    input[type="submit"] {
    background: #fff;
    font-weight: 500;
    padding: 0 50px;
    height: 60px;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 0.1em;
    border: 1px solid #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.shop-details
    .product-tabs
    #review-form
    .content-info-reviews
    .form-submit
    input[type="submit"]:hover {
    background: #000;
    border-color: #000;
    color: #fff;
}
.shop-details .product-related {
    margin-top: 50px;
}
.shop-details .product-related .block-title {
    text-align: center;
    margin-bottom: 50px;
}
.shop-details .product-related .block-title h2 {
    font-size: 28px;
    color: #000;
    margin: 0;
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
}
.shop-details .product-related .block-title h2:before {
    content: "";
    width: 30%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
}

@media (max-width: 630px) {
    .accordion-header {
        font-size: 20px !important;
    }
}
@media (max-width: 500px) {
    .accordion-header {
        font-size: 20px !important;
    }
}
@media screen and (min-device-width: 360px) and (max-device-width: 481px) {
    .accordion-header {
        font-size: 15px !important;
    }
}

/**
   * 7.4. Shop - Cart
   */
.shop-cart .cart-items thead {
    border: 1px solid #e8e8e8;
}
.shop-cart .cart-items thead tr th {
    font-size: 18px;
    color: #000;
    padding: 15px;
    font-weight: 500;
    border-bottom-width: 1px;
}
.shop-cart .cart-items tbody {
    border: 1px solid #e8e8e8;
    border-top: 0;
}
.shop-cart .cart-items tbody tr td {
    padding: 15px;
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    vertical-align: middle;
}
.shop-cart .cart-items tbody tr td.product-thumbnail {
    display: flex;
    align-items: center;
}
.shop-cart .cart-items tbody tr td.product-thumbnail img {
    width: 80px;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a {
    font-size: 16px;
    color: #868686;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a:hover {
    color: #000;
}
.shop-cart .cart-items tbody tr td.product-price span,
.shop-cart .cart-items tbody tr td.product-subtotal span {
    color: #000;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity input.qty {
    border-left: 0;
    border-right: 0;
    width: 30px;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity .minus {
    border-right: 0;
    width: 30px;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity .plus {
    border-left: 0;
    width: 30px;
}
.shop-cart .cart-items tbody tr td.product-remove a {
    font-size: 0;
    text-align: center;
}
.shop-cart .cart-items tbody tr td.product-remove a:before {
    display: inline-block;
    content: "\ea02";
    font-family: feather;
    font-size: 10px;
    color: #000 !important;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    line-height: 15px;
}
.shop-cart .cart-items tbody tr td.product-remove a:hover:before {
    color: #fff !important;
    background: #000;
    border-color: #000;
}
.shop-cart .cart-items .actions .bottom-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.shop-cart .cart-items .actions .coupon {
    flex: 0 0 50%;
    display: flex;
}
.shop-cart .cart-items .actions .coupon input {
    line-height: 40px;
    padding: 0 10px;
    flex: 1;
    margin-right: 5px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.shop-cart .cart-items .actions .bottom-cart .button {
    padding: 0 35px;
    background: #000;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 40px;
    cursor: pointer;
    letter-spacing: 0.1em;
    font-weight: 500;
    border: 1px solid #000;
}
.shop-cart .cart-items .actions .bottom-cart .button:hover {
    opacity: 0.8;
}
.shop-cart .cart-items .actions .bottom-cart h2 {
    margin: 0;
    font-size: 15px;
}
.shop-cart .cart-totals {
    background: #f6f6f6;
    padding-bottom: 35px;
    max-width: 400px;
    margin-left: auto;
}
.shop-cart .cart-totals h2 {
    font-size: 18px;
    color: #000;
    padding: 15px 30px 11px;
    background: #e9e9e9;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
    border-bottom: 0;
}
.shop-cart .cart-totals .cart-subtotal {
    display: flex;
    padding: 15px 30px;
    align-items: center;
    margin-top: 10px;
}
.shop-cart .cart-totals .cart-subtotal > * {
    flex: 1;
}
.shop-cart .cart-totals .cart-subtotal .title {
    color: #000;
    font-size: 16px;
}
.shop-cart .cart-totals .cart-subtotal span {
    font-size: 18px;
    color: #000;
    font-weight: 500;
    letter-spacing: 1.1px;
}
.shop-cart .cart-totals .shipping-totals {
    display: flex;
    padding: 15px 30px;
    align-items: center;
}
.shop-cart .cart-totals .shipping-totals > * {
    flex: 1;
}
.shop-cart .cart-totals .shipping-totals .title {
    color: #000;
    font-size: 16px;
}
.shop-cart .cart-totals .shipping-totals .shipping-methods {
    padding: 0;
    list-style: none;
    margin-bottom: 10px;
}
.shop-cart .cart-totals .shipping-totals .shipping-desc {
    margin-bottom: 0;
}
.shop-cart .cart-totals .order-total {
    display: flex;
    padding: 15px 30px;
    align-items: center;
}
.shop-cart .cart-totals .order-total > * {
    flex: 1;
}
.shop-cart .cart-totals .order-total .title {
    color: #000;
    font-size: 16px;
}
.shop-cart .cart-totals .order-total span {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    letter-spacing: 1.1px;
}
.shop-cart .cart-totals .proceed-to-checkout {
    padding: 0 30px;
    margin-top: 15px;
}
.shop-cart .cart-totals .proceed-to-checkout .button {
    background: #000;
    color: #fff;
    padding: 15px 20px;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 13px;
    display: inline-block;
    width: 100%;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 500;
    border: 1px solid #000;
    cursor: pointer;
}
.shop-cart .cart-totals .proceed-to-checkout .button:hover {
    opacity: 0.8;
}
.shop-cart-empty .cart-empty {
    border-top: 3px solid #000;
    text-transform: capitalize;
    padding: 12px 22px;
    margin: 0 0 24px;
    position: relative;
    background-color: #f7f6f7;
    color: #515151;
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
    width: 100%;
}
.shop-cart-empty .return-to-shop .button {
    line-height: 34px;
    background: #000;
    color: #fff;
    padding: 0 20px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    height: 40px;
}
.shop-cart-empty .return-to-shop .button:before {
    content: "\23";
    font-size: 18px;
    font-family: ElegantIcons;
    margin: 0 5px 0 0;
    position: relative;
    top: 3px;
}
.shop-cart-empty .return-to-shop .button:hover {
    background: rgba(0, 0, 0, 0.75);
}

/**
   * 7.5. Shop - Checkout
   */
.shop-checkout .checkout h3 {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 30px;
}
.shop-checkout .checkout .form-row {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
}
.shop-checkout .checkout .form-row label {
    color: #000;
    font-weight: 500;
    float: left;
    margin-bottom: 0;
    display: block;
}
.shop-checkout .checkout .form-row .required {
    color: #e42234;
    font-weight: 400;
    border: 0;
}
.shop-checkout .checkout .form-row .input-wrapper {
    width: 100%;
    float: left;
}
.shop-checkout .checkout .form-row .input-wrapper .input-text {
    width: 100%;
    border-width: 0 0 2px;
    border-color: #e5e5e5;
    padding: 0;
    height: 30px;
    line-height: 30px;
    color: #000;
}
.shop-checkout .checkout .form-row .selection .select2-selection {
    height: 30px;
    line-height: 30px;
    border-width: 0 0 2px;
    border-color: #e5e5e5;
    border-radius: 0;
}
.shop-checkout .checkout .form-row .selection .select2-selection span {
    line-height: 30px;
    padding: 0;
    height: 30px;
}
.shop-checkout .account-fields {
    margin-bottom: 30px;
}
.shop-checkout .checkout .account-fields .form-row {
    margin-bottom: 15px;
}
.shop-checkout .account-fields .checkbox span {
    margin-left: 10px;
}
.shop-checkout .ship-to-different-address .checkbox span {
    margin-left: 10px;
}
.shop-checkout .checkout .form-row .input-wrapper textarea {
    height: 100px !important;
}
.shop-checkout .checkout-review-order {
    padding: 30px 40px;
    border: 1px solid #000;
}
.shop-checkout .checkout-review-order h2 {
    margin: 0;
    font-size: 15px;
}
.shop-checkout .checkout-review-order .review-order-title {
    font-size: 22px;
    color: #000;
    margin-bottom: 22px;
}
.shop-checkout .checkout-review-order .cart-item {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
}
.shop-checkout .checkout-review-order .cart-item .info-product {
    display: flex;
}
.shop-checkout
    .checkout-review-order
    .cart-item
    .info-product
    .product-thumbnail {
    max-width: 55px;
}
.shop-checkout
    .checkout-review-order
    .cart-item
    .info-product
    .product-thumbnail
    img {
    border: 1px solid #e8e8e8;
}
.shop-checkout .checkout-review-order .cart-item .info-product .product-name {
    padding-left: 20px;
    color: #000;
    text-transform: uppercase;
    word-break: break-word;
    font-size: 14px;
}
.shop-checkout
    .checkout-review-order
    .cart-item
    .info-product
    .product-name
    .product-quantity {
    display: block;
    font-weight: 500;
}
.shop-checkout .checkout-review-order .cart-item .product-total span {
    color: #000;
    font-weight: 500;
    letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .cart-subtotal,
.shop-checkout .checkout-review-order .order-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dedede;
    padding: 27px 0;
}
.shop-checkout .checkout-review-order .cart-subtotal {
    margin-top: 30px;
}
.shop-checkout .checkout-review-order .cart-subtotal span,
.shop-checkout .checkout-review-order .order-total span {
    color: #000;
    font-weight: 500;
    letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .shipping-totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dedede;
    padding: 27px 0;
}
.shop-checkout .checkout-review-order .shipping-methods {
    text-align: right;
    padding: 0;
    list-style: none;
    margin: 0;
}
.shop-checkout .checkout-review-order .order-total .total-price span {
    font-size: 18px;
    color: #000;
    letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .checkout-payment .payment-methods {
    padding: 20px;
    margin-bottom: 30px;
    list-style: none;
    border: 1px solid #d9d9d9;
}
.shop-checkout .checkout-review-order .checkout-payment .custom-radio li {
    margin-bottom: 10px;
}
.shop-checkout
    .checkout-review-order
    .checkout-payment
    .custom-radio
    li:last-child {
    margin-bottom: 0;
}
.shop-checkout .checkout-review-order .checkout-payment .custom-radio li label {
    color: #000;
    font-weight: 500;
}
.shop-checkout .checkout-review-order .checkout-payment .button {
    background: #000;
    color: #fff;
    padding: 15px 20px;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 13px;
    display: inline-block;
    width: 100%;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 500;
    border: 1px solid #000;
    cursor: pointer;
}
.shop-checkout .checkout-review-order .checkout-payment .button:hover {
    opacity: 0.8;
}

/**
   * 7.6. Shop - Wishlist
   */
.shop-wishlist .wishlist-items {
    border: 1px solid #e5e5e5;
}

/*===================================================================
  8. Blog
  ====================================================================*/
/**
   * 8.1. Blog - Grid
   */
.posts-list.grid .post-entry {
    margin-bottom: 40px;
}
.posts-list.grid .post-entry .post-categories {
    margin-top: 10px;
}
.posts-list.grid .post-entry .post-categories a {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.8px;
}
.posts-list.grid .post-entry .post-title {
    font-size: 20px;
    line-height: 1.1;
    width: 100%;
    margin: 3px 0 10px;
}
.posts-list.grid .post-entry .post-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #868686;
    font-size: 13px;
    letter-spacing: 0.5px;
}
.posts-list.grid .post-entry .post-meta .post-time {
    display: flex;
    align-items: center;
}
.posts-list.grid .post-entry .post-meta .post-time:after {
    content: "";
    margin: 0 10px;
    height: 1px;
    width: 10px;
    display: inline-block;
    background: #d5d5d5;
}

/**
   * 8.2. Blog - List
   */
.posts-list.list .post-entry {
    margin-bottom: 55px;
}
.posts-list.list .post-entry .post-image {
    margin-bottom: 20px;
}
.posts-list.list .post-entry .post-title {
    font-size: 34px;
    line-height: 1.3;
    margin: 0 0 10px;
}
.posts-list.list .post-entry .post-meta {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    color: #ababab;
    letter-spacing: 0.5px;
}
.posts-list.list .post-entry .post-meta a {
    color: #ababab;
}
.posts-list.list .post-entry .post-meta i {
    margin-right: 6px;
}
.posts-list.list .post-entry .post-meta .post-time,
.posts-list.list .post-entry .post-meta .post-categories,
.posts-list.list .post-entry .post-meta .post-comment {
    display: flex;
    align-items: center;
}
.posts-list.list .post-entry .post-meta .post-time:after,
.posts-list.list .post-entry .post-meta .post-categories:after {
    content: "";
    width: 1px;
    height: 18px;
    margin: 0 15px;
    display: inline-block;
    background: #dedede;
}
.posts-list.list .post-entry .post-excerpt {
    margin-bottom: 25px;
    font-size: 15px;
}
.posts-list.list .post-entry .post-btn a {
    font-size: 12px;
    text-transform: uppercase;
}

/**
   * 8.3. Blog - Details
   */
.post-details .post-title {
    margin-top: 20px;
    margin-bottom: 10px;
}
.post-details.no-sidebar .post-title {
    font-size: 36px;
    text-align: center;
}
.post-details.no-sidebar .post-meta {
    align-content: center;
    justify-content: center;
}
.post-details .post-meta {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #ababab;
    letter-spacing: 0.5px;
}
.post-details .post-meta a {
    color: #ababab;
}
.post-details .post-meta i {
    margin-right: 6px;
}
.post-details .post-meta .post-time,
.post-details .post-meta .post-categories,
.post-details .post-meta .post-comment {
    display: flex;
    align-items: center;
}
.post-details .post-meta .post-time:after,
.post-details .post-meta .post-categories:after {
    content: "";
    width: 1px;
    height: 18px;
    margin: 0 15px;
    display: inline-block;
    background: #dedede;
}
.post-details .post-content blockquote {
    font-weight: 500;
    margin: 30px 0;
    padding: 0 0 0 50px;
    color: #666;
    border-left: 2px solid #000;
    position: relative;
    font-size: 16px;
}
.post-details .post-content blockquote i {
    display: block;
    font-size: 23px;
    color: #dadada;
    margin-bottom: 20px;
}
.post-details .post-content blockquote h2 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 15px 0 0;
}
.post-details .post-content .content-img {
    margin-top: 30px;
}
.post-details .post-content-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-bottom: 35px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}
.post-details .post-content-entry .tags-links {
    margin-top: 20px;
}
.post-details .post-content-entry .tags-links label {
    margin: 0;
    color: #000;
}
.post-details .post-content-entry .tags-links a {
    font-size: 14px;
    color: #868686;
    font-weight: 500;
    line-height: 40px;
    margin: 2.5px;
    border: 1px solid #e5e5e5;
    display: inline-block;
    padding: 0 20px;
    background: transparent;
    white-space: nowrap;
}
.post-details .post-content-entry .tags-links a:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}
.post-details .post-content-entry .entry-social-share {
    margin-bottom: 0;
    margin-top: 20px;
}
.post-details .post-content-entry .entry-social-share label {
    margin: 0 3px 0 0;
    color: #000;
}
.post-details .post-content-entry .entry-social-share .social-share {
    display: inline-block;
}
.post-details .post-content-entry .entry-social-share .social-share a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #e5e5e5;
    font-size: 0;
}
.post-details .post-content-entry .entry-social-share .social-share a:hover {
    background: #000;
    border-color: #000;
}
.post-details .post-content-entry .entry-social-share .social-share a i {
    text-align: center;
    font-size: 13px;
    color: #000;
}
.post-details .post-content-entry .entry-social-share .social-share a:hover i {
    color: #fff;
}
.post-details .prev-next-post {
    padding: 30px 0;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}
.post-details .prev-next-post:before {
    content: "";
    position: absolute;
    left: 50%;
    top: calc(50% - 39px);
    width: 1px;
    height: 78px;
    background: #e5e5e5;
}
.post-details .prev-next-post .next-post,
.post-details .prev-next-post .previous-post {
    width: 50%;
    float: left;
}
.post-details .prev-next-post .previous-post {
    padding-right: 15px;
}
.post-details .prev-next-post .next-post {
    text-align: right;
    float: right;
    padding-left: 15px;
}
.post-details .prev-next-post .next-post a,
.post-details .prev-next-post .previous-post a {
    color: #ababab;
    font-size: 15px;
    text-transform: capitalize;
    display: block;
}
.post-details .prev-next-post .hover-extend {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.post-details .prev-next-post .next-post a .title,
.post-details .prev-next-post .previous-post a .title {
    overflow-wrap: break-word;
    margin: 0;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.post-details .prev-next-post .next-post a:hover .hover-extend,
.post-details .prev-next-post .next-post a:hover .title,
.post-details .prev-next-post .previous-post a:hover .hover-extend,
.post-details .prev-next-post .previous-post a:hover .title {
    color: #000;
}
.post-details .comments-area .comments-title {
    font-size: 32px;
    margin-bottom: 0;
    color: #000;
    margin-top: 40px;
    text-align: center;
}
.post-details .comments-area .comments-list {
    list-style: none;
    padding: 0;
    margin-top: 30px;
}
.post-details .comments-area .comments-list .comment-item {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 35px;
    border-bottom: 1px solid #e5e5e5;
    display: block;
}
.post-details .comments-area .comments-list .comment-item:last-child {
    margin-bottom: 0;
}
.post-details .comments-area .comments-list .comment-item .comment-avatar {
    float: left;
}
.post-details .comments-area .comments-list .comment-item .comment-avatar img {
    margin: 0;
    margin-right: 20px;
    min-width: 70px;
}
.post-details
    .comments-area
    .comments-list
    .comment-item
    .comment-content-wrap {
    overflow: hidden;
}
.post-details .comments-area .comments-list .comment-item .comment-author {
    line-height: 1.2;
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 0.1em;
}
.post-details .comments-area .comments-list .comment-item .comment-time {
    margin-bottom: 6px;
    font-size: 14px;
}
.post-details .comments-area .comments-list .comment-item .comment-content {
    color: #868686;
    font-weight: 400;
}
.post-details .comments-area .comments-list .comment-item .comment-content p {
    margin: 0;
    line-height: 1.6;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
    position: absolute;
    top: 5px;
    right: 0;
    color: #c2c2c2;
    font-weight: 400;
    font-size: 0;
    font-weight: 500;
}
.post-details
    .comments-area
    .comments-list
    .comment-item
    .comment-reply-link:hover {
    color: #000;
}
.post-details
    .comments-area
    .comments-list
    .comment-item
    .comment-reply-link:before {
    content: "\f112";
    display: inline-block;
    font-size: 14px;
    font-family: FontAwesome;
}
.post-details .comments-area .comment-form {
    margin-top: 40px;
    text-align: center;
}
.post-details .comments-area .comment-form .form-header h3 {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 20px;
    color: #000;
}
.post-details .comments-area .comment-form .comment-notes {
    margin-bottom: 20px;
}
.post-details .comments-area .comment-form .form-group {
    position: relative;
    margin-bottom: 30px;
}
.post-details .comments-area .comment-form .form-group .form-control {
    height: 47px;
    padding: 10px 20px;
    border: none;
    background: #f5f5f5;
}
.post-details .comments-area .comment-form .form-group textarea.form-control {
    height: auto;
}
.post-details
    .comments-area
    .comment-form
    .form-group
    .form-control::placeholder {
    color: #999;
}
.post-details .comments-area .comment-form .form-group .btn {
    padding: 0 30px;
    height: 50px;
    line-height: 48px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}

/*===================================================================
  9. Pages
  ====================================================================*/
/**
   * 9.1. Login / Register
   */
.page-login-register .box-form-login {
    border: 2px solid #eaeaea;
    padding: 50px 0 40px;
    height: 100%;
}
.page-login-register .box-form-login h2 {
    text-align: center;
    position: relative;
    margin: 0 0 40px;
    font-size: 20px;
    padding-bottom: 15px;
}
.page-login-register .box-form-login h2:before {
    font-size: 21px;
    content: "\e90c";
    font-family: wpbingofont;
    display: inline-block;
    margin-right: 10px;
}
.page-login-register .box-form-login h2:after {
    content: "";
    width: 132px;
    height: 2px;
    background: #000;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 66px);
}
.page-login-register .box-form-login h2.register:before {
    content: "\e915";
}
.page-login-register .box-form-login .box-content {
    padding: 0 90px;
}
.page-login-register .box-form-login .box-content .required {
    color: red;
}
.page-login-register .box-form-login .box-content input {
    width: 100%;
    height: 55px;
    line-height: 55px;
}
.page-login-register .box-form-login .box-content .email input,
.page-login-register .box-form-login .box-content .password input,
.page-login-register .box-form-login .box-content .username input {
    padding: 0 20px;
    margin-bottom: 15px;
}
.page-login-register .box-form-login .box-content .rememberme-lost {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .remember-me {
    margin-right: 10px;
    position: relative;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .remember-me
    input {
    position: absolute;
    opacity: 0;
    height: 22px;
    cursor: pointer;
}
.page-login-register .box-form-login .box-content .rememberme-lost .inline {
    color: #000;
    font-weight: 500;
    cursor: pointer;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .inline:before {
    width: 16px;
    height: 16px;
    content: "";
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #000;
    margin-right: 5px;
    position: relative;
    top: 3px;
    z-index: 2;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .remember-me
    input:checked
    + label:before {
    border-color: #0075ff;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .inline:after {
    width: 16px;
    height: 16px;
    content: "";
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    top: 6px;
    border: 2.5px solid #fff;
    left: 0;
    background: #0075ff;
    z-index: 1;
    opacity: 0;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .remember-me
    input:checked
    + label:after {
    opacity: 1;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .lost-password {
    font-weight: 500;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .lost-password
    a {
    display: inline-block;
    position: relative;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .lost-password
    a:hover:before {
    background: #000;
    width: 50%;
}
.page-login-register
    .box-form-login
    .box-content
    .rememberme-lost
    .lost-password
    a:before {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #000;
}
.page-login-register .box-form-login .box-content .button-login,
.page-login-register .box-form-login .box-content .button-register {
    text-align: center;
    margin-top: 20px;
}
.page-login-register
    .box-form-login
    .box-content
    .button-login
    input[type="submit"],
.page-login-register
    .box-form-login
    .box-content
    .button-register
    input[type="submit"] {
    background: #000;
    border: 0;
    font-size: 13px;
    padding: 0;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.page-login-register
    .box-form-login
    .box-content
    .button-login
    input[type="submit"]:hover,
.page-login-register
    .box-form-login
    .box-content
    .button-register
    input[type="submit"]:hover {
    opacity: 0.8;
}

/**
   * 9.2. Forget Password
   */
.page-forget-password .reset-password .form-row {
    margin: 0;
    margin-bottom: 15px;
}
.page-forget-password .reset-password .form-row label {
    display: block;
    width: 100%;
}
.page-forget-password .reset-password .form-row .input-text {
    width: 100%;
}
.page-forget-password .reset-password .form-row .button {
    color: #fff;
    background: #000;
    font-size: 15px;
    padding: 0 50px;
    line-height: 50px;
    cursor: pointer;
    border: 0;
    margin-top: 10px;
}
.page-forget-password .reset-password .form-row .button:hover {
    opacity: 0.8;
}

/**
   * 9.3. My Account
   */
.page-my-account .my-account-navigation {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    margin-bottom: 40px;
    padding: 20px 30px;
    width: 30%;
    float: left;
}
.page-my-account .my-account-navigation ul {
    padding: 0;
    list-style: none;
    border-bottom: none;
}
.page-my-account .my-account-navigation ul li {
    border-bottom: 1px solid hsla(0, 0%, 90%, 0.5);
    list-style-type: none;
    padding: 10px 0;
    vertical-align: middle;
    width: 100%;
}
.page-my-account .my-account-navigation ul li:last-child {
    border-bottom: none;
}
.page-my-account .my-account-navigation ul li a,
.page-my-account .my-account-navigation ul li span {
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    font-weight: 500;
    padding: 0;
    border: none !important;
}
.page-my-account .my-account-navigation ul li a:hover,
.page-my-account .my-account-navigation ul li a:focus,
.page-my-account .my-account-navigation ul li a.active {
    background: transparent;
}
.page-my-account .my-account-content {
    width: 68%;
    float: right;
    font-size: 15px;
    line-height: 1.4;
}
.page-my-account .my-account-content strong {
    font-weight: 500;
}
.page-my-account .my-account-content a:hover {
    opacity: 0.7;
}
.page-my-account .my-account-orders table {
    border: 1px solid #e6e6e6;
}
.page-my-account .my-account-orders table tr th {
    border: 0;
    padding: 12px 20px;
    line-height: 1.8;
    vertical-align: top;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.page-my-account .my-account-orders table tr td {
    padding: 10px 20px;
    line-height: 1.8;
    vertical-align: top;
}
.page-my-account .my-account-addresses .addresses {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.page-my-account .my-account-addresses .addresses .addresses-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 15px 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col .col-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 20px;
    background: #f5f5f5;
}
.page-my-account .my-account-addresses .addresses .addresses-col .col-title h3 {
    font-size: 20px;
    margin-bottom: 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
    padding: 15px 20px;
    border: 1px solid #f5f5f5;
    margin-bottom: 0;
}
.page-my-account .my-account-account-details .form-row {
    margin: 0;
    margin-bottom: 15px;
}
.page-my-account .my-account-account-details .form-row label {
    display: block;
    width: 100%;
}
.page-my-account .my-account-account-details .form-row em {
    margin-top: 5px;
    display: block;
}
.page-my-account .my-account-account-details .form-row .input-text {
    width: 100%;
}
.page-my-account .my-account-account-details fieldset {
    margin-top: 30px;
}
.page-my-account .my-account-account-details legend {
    color: #000;
    font-weight: 400;
    margin-top: 30px;
}
.page-my-account .my-account-account-details .form-row .button {
    color: #fff;
    background: #000;
    font-size: 15px;
    padding: 0 50px;
    line-height: 50px;
    cursor: pointer;
    border: 0;
    margin-top: 10px;
}
.page-my-account .my-account-account-details .form-row .button:hover {
    opacity: 0.8;
}

/**
   * 9.4. About Us
   */
.page-about-us .block .block-title {
    text-align: center;
    margin-bottom: 50px;
}
.page-about-us .block .block-title h2 {
    font-size: 50px;
}
.page-about-us .block .block-title .sub-title {
    font-size: 22px;
}

/**
   * 9.5. Page FAQ
   */
.page-faq .faq-section {
    margin-bottom: 60px;
}
.page-faq .faq-section .section-title h2 {
    font-size: 30px;
    margin-bottom: 20px;
}
.page-faq .faq-section .faq-item {
    margin-bottom: 20px;
}
.page-faq .faq-section .faq-item .faq-question {
    color: #656565;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 6px;
}

/**
   * 9.6. Page 404
   */
.page-404 {
    position: relative;
    text-align: center;
}
.page-404 .content-page-404 {
    padding: 100px 0 60px;
}
.page-404 .content-page-404 .title-error {
    font-size: 300px;
    line-height: 1;
    color: #000;
}
.page-404 .content-page-404 .sub-title {
    font-size: 50px;
    color: #000;
    font-weight: 500;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 10px;
}
.page-404 .content-page-404 .sub-title:before {
    position: absolute;
    content: "";
    width: 140px;
    height: 2px;
    bottom: 0;
    left: calc(50% - 70px);
    background: #e1e1e1;
}
.page-404 .content-page-404 .sub-error {
    max-width: 325px;
    margin: auto;
    font-size: 16px;
}
.page-404 .content-page-404 .button {
    display: inline-block;
    margin-top: 30px;
    padding: 0 40px;
    height: 50px;
    line-height: 46px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    font-size: 14px;
    background: #000;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    position: relative;
    z-index: 1;
    border: 2px solid #000;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
.page-404 .content-page-404 .button:after {
    display: inline-block;
    content: "\24";
    font-family: eleganticons;
    font-size: 20px;
    line-height: 1;
    position: relative;
    top: 5px;
}
.page-404 .content-page-404 .button:hover {
    background: #fff;
    color: #000;
}

/*===================================================================
  10. Footer
  ====================================================================*/
#site-footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 70px;
    margin-top: 70px;
    color: #868686;
    background: rgb(248, 233, 213);
}
#site-footer.m-t-0,
.home #site-footer {
    margin-top: 0;
}
#site-footer.m-t-30 {
    margin-top: 30px;
}
#site-footer.small-space {
    padding-top: 50px;
}
#site-footer.background {
    background: #f4f4f4;
    border-top: none;
}
#site-footer.dark {
    background: #161616;
}
#site-footer .footer-top {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 70px;
    margin-bottom: 70px;
}
#site-footer.small-space .footer-top {
    padding-bottom: 50px;
    margin-bottom: 50px;
}
#site-footer.small-space .row {
    margin: 0 -15px;
}
#site-footer.small-space .row > div {
    padding: 0 15px;
}
#site-footer .footer .block {
    margin-bottom: 40px;
}
#site-footer .footer .block.m-b-15 {
    margin-bottom: 15px;
}
#site-footer .footer .block.m-b-20 {
    margin-bottom: 20px;
}
#site-footer .footer .block.m-b-30 {
    margin-bottom: 30px;
}
#site-footer .block .block-title {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1;
    margin-bottom: 20px;
}
#site-footer.dark .block .block-title {
    color: #fff;
}
#site-footer ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
#site-footer ul li,
#site-footer p {
    margin-bottom: 8px;
}
#site-footer ul li a {
    color: #868686;
}
#site-footer ul li a:hover {
    color: #000;
}
#site-footer.dark ul li a:hover {
    color: #fff;
}
#site-footer .footer-bottom {
    border-top: 1px solid #e5e5e5;
    margin-top: 30px;
    padding: 20px 0;
}
#site-footer.dark .footer-bottom {
    border-top: 1px solid #2d2d2d;
}
#site-footer .footer-bottom.background-white {
    background: #fff;
}
#site-footer .footer-bottom.no-border-top {
    border-top: none;
}
#site-footer.small-space .footer-bottom {
    margin-top: 10px;
}
#site-footer .footer-bottom .block-widget-wrap > .row {
    align-items: center;
}
#site-footer .footer-bottom p,
#site-footer .footer-bottom ul {
    margin-bottom: 0;
}
#site-footer .footer-bottom .footer-right {
    text-align: right;
    padding-right: 75px !important;
}
#site-footer .footer-bottom .footer-right .block-menu ul li {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
}
#site-footer .footer-bottom .footer-right .block-menu ul li:first-child {
    margin-left: 0;
}
#site-footer .footer-bottom .footer-right .block-menu ul li:last-child {
    margin-right: 0;
}
#site-footer .footer-bottom .footer-right .block-menu ul li a {
    color: #000;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1.8;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
}
#site-footer .footer-bottom .footer-right .block-menu ul li a:before {
    position: absolute;
    content: "";
    background: #000;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#site-footer .footer-bottom .footer-right .block-menu ul li a:hover:before {
    width: 100%;
}
#site-footer .social-link li {
    display: inline-block;
    margin: 0 10px;
    text-align: center;
}
#site-footer .social-link li:first-child {
    margin-left: 0;
}
#site-footer .social-link li:last-child {
    margin-right: 0;
}
#site-footer .social-link li a {
    display: inline-block;
}
#site-footer .social-link li a i {
    color: #000;
    font-size: 20px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#site-footer.dark .social-link li a i {
    color: #fff;
}
#site-footer .social-link li a:hover i {
    font-size: 20px;
}
#site-footer.three-columns {
    padding-top: 0;
}
#site-footer.three-columns .column-center {
    text-align: center;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
#site-footer.three-columns .column-center .block:last-child {
    margin-bottom: 0;
}
#site-footer.three-columns .column-left,
#site-footer.three-columns .column-right {
    -ms-flex: 0 0 36%;
    flex: 0 0 36%;
    max-width: 36%;
    padding-top: 60px;
    padding-bottom: 20px;
}
#site-footer.three-columns .column-right {
    display: flex;
    justify-content: flex-end;
}
#site-footer.three-columns .column-right .column-wrap {
    max-width: 360px;
}
#site-footer.three-columns .footer-bottom {
    margin-top: 0;
}
#site-footer.four-columns .column-1,
#site-footer.four-columns .column-2,
#site-footer.four-columns .column-3 {
    -ms-flex: 0 0 22.5%;
    flex: 0 0 22.5%;
    max-width: 22.5%;
}
#site-footer.four-columns .column-4 {
    -ms-flex: 0 0 32.5%;
    flex: 0 0 32.5%;
    max-width: 32.5%;
}
.otp-box input {
    text-align: center;
    width: 50px;
    height: 50px;
    margin: 15px;
    border-radius: 6px;
    border: 1px solid lightslategrey;
    font-size: 20px;
}
.otp-text {
    font-size: 13px;
}

.w-200 {
    width: 300px;
}

.carousel .slide:focus {
    outline: none;
}

.scrole-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
    /* transition: background-color 0.3s ease, backdrop-filter 0.3s ease; */
    background: #f8e9d5;
    /* backdrop-filter: blur(0.1px);  */
    width: 100%;
}

/* .scrole-header.scrolled {
  background: #F8E9D5; 
  backdrop-filter: none; 
} */

.mobile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

@media (min-width: 345px) and (max-width: 420px) {
    .label-text {
        font-size: 5vw !important; /* Increase font size for small screens */
    }

    .hot {
        font-size: 4vw !important; /* Increase font size for small screens */
    }

    .triangle {
        border-right: 15vw solid transparent !important; /* Adjust triangle size */
        border-top: 15vw solid transparent !important;
    }

    .triangle div {
        width: 15vw !important; /* Adjust triangle size */
        height: 15vw !important; /* Adjust triangle size */
        top: -15vw !important; /* Adjust position */
    }
}

.max-390{
    max-height: 368px;
}